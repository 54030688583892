import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';

export type Detail = { key: string; value: string };
type Props = {
  title: string;
  details?: Detail[];
  point: number;
};

const Container: React.VFC<Props> = ({ title, details, point }) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter title={title} details={details} point={point} />
  ) : (
    <Presenter title={title} details={details} point={point} />
  );
};

export { Container as PointLog };
