import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import QuestionIcon from '/@/assets/shared/benefitOfPointCharge/modalWithButton/question-icon.svg';
import { BenefitOfPointChargeModal } from '../modal/Index';
import { neoTheme } from '/@/utils/theme';

const StyledBenefitOfPointChargeModalButton = styled(Button)(({ theme }) => ({
  textAlign: 'center',
  textDecoration: 'none',
  backgroundColor: neoTheme.palette.common.white,
  borderRadius: theme.spacing(2.75),
  border: `2px solid ${neoTheme.palette.secondary.light}`,
}));
const StyledQuestionIcon = styled('img')(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  marginRight: theme.spacing(0.5),
}));
const StyledPointQuestionText = styled(Typography)({
  color: neoTheme.palette.common.black,
  fontWeight: 'bold',
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
});

type Props = {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
};

const Presenter: React.VFC<Props> = ({
  isOpenModal,
  handleOpenModal,
  handleCloseModal,
}) => (
  <>
    <StyledBenefitOfPointChargeModalButton
      onClick={handleOpenModal}
      disableElevation
      variant="contained"
      data-cy="benefitOfPointChargeModalButton"
      data-gtm-click="shared-benefitOfPointCharge-modalWithButton-openButton"
    >
      <StyledQuestionIcon src={QuestionIcon} alt="question" />
      <StyledPointQuestionText>ポイントとは？</StyledPointQuestionText>
    </StyledBenefitOfPointChargeModalButton>
    {isOpenModal && (
      <BenefitOfPointChargeModal handleClose={handleCloseModal} />
    )}
  </>
);

export { Presenter };
