import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { PointFeature } from '/@/api/graphql/internalApi/types';
import { useNavigate } from 'react-router-dom';
import { Location } from 'history';
import { useAppSelector } from '/@/store';

type Props = {
  pointFeatures: PointFeature[];
};

const Container: React.VFC<Props> = ({ pointFeatures }) => {
  const navigate = useNavigate();
  const navigateDestination = (destination: Location) => {
    navigate(destination);
  };

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      pointFeatures={pointFeatures}
      navigateDestination={navigateDestination}
    />
  ) : (
    <Presenter
      pointFeatures={pointFeatures}
      navigateDestination={navigateDestination}
    />
  );
};

export { Container as FullImagePointFeatures };
