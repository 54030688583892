import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';

const Container: React.VFC<Record<string, never>> = () => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter />
  ) : (
    <Presenter />
  );
};

export { Container as BenefitOfPointCharge };
