import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { isPointsCharged } from '/@/api/graphql/internalApi/queries/getPointLogs';
import { useAppSelector } from '/@/store';

type OwnProps = {
  skipConfirm?: boolean;
};

const Container: React.VFC<OwnProps> = ({ skipConfirm }) => {
  const [hasCharged, setHasCharged] = useState<boolean | null>(null);

  useEffect(() => {
    isPointsCharged(setHasCharged);
  }, []);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter hasCharged={hasCharged} skipConfirm={skipConfirm} />
  ) : (
    <Presenter hasCharged={hasCharged} skipConfirm={skipConfirm} />
  );
};

export { Container as PointChargeSection };
