import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const Container: React.VFC<Record<string, never>> = () => {
  const exchangedGifts = useAppSelector((state) => state.app.exchangedGifts);
  const { pushPointExchangeEvent } = useDataLayer();
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate(PATHS.HOME);
  };

  useEffect(() => {
    if (exchangedGifts) {
      pushPointExchangeEvent({
        point: exchangedGifts?.pointAmount,
        itemUid: exchangedGifts?.item.uid,
        itemName: exchangedGifts?.item.contentName,
        brandUid: exchangedGifts?.item.brand.uid,
        brandName: exchangedGifts?.item.brand.name,
        customData: {
          point: exchangedGifts?.pointAmount,
          itemUid: exchangedGifts?.item.uid,
          itemName: exchangedGifts?.item.contentName,
          brandUid: exchangedGifts?.item.brand.uid,
          brandName: exchangedGifts?.item.brand.name,
        },
      });
    }
  }, [pushPointExchangeEvent, exchangedGifts]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter exchangedGifts={exchangedGifts} navigateToHome={navigateToHome} />
  ) : (
    <Presenter exchangedGifts={exchangedGifts} />
  );
};
export { Container as ExchangedGifts };
