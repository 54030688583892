import React from 'react';
import { styled } from '@mui/system';
import {
  LayoutEnum,
  PointFeatureGroup,
} from '/@/api/graphql/internalApi/types';
import { FullImagePointFeatures } from './FullImagePointFeatures/Index';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';

const StyledPointFeatureGroupContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const StyledPointFeaturesContainer = styled('div')({});

type Props = {
  pointFeatureGroup: PointFeatureGroup;
};

const Presenter: React.VFC<Props> = ({ pointFeatureGroup }) => (
  <StyledPointFeatureGroupContainer>
    <BaseTypography fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.MD} isBold>{pointFeatureGroup.label}</BaseTypography>
    <StyledPointFeaturesContainer>
      {pointFeatureGroup.layout === LayoutEnum.FullImage && (
        <FullImagePointFeatures
          pointFeatures={pointFeatureGroup.pointFeatures}
        />
      )}
    </StyledPointFeaturesContainer>
  </StyledPointFeatureGroupContainer>
);

export { Presenter };
