import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const Container: React.VFC<Record<string, never>> = () => {
  const myPoint = useAppSelector((state) => state.app.pointWallet?.point);
  const getPointWalletStatus = useAppSelector(
    (state) => state.api.getPointWallet.status,
  );
  const chargedPoint = useAppSelector(
    (state) => state.app.chargedPoint?.pointAmount,
  );
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate(PATHS.HOME);
  };

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      chargedPoint={chargedPoint}
      myPoint={myPoint}
      navigateToHome={navigateToHome}
      getPointWalletStatus={getPointWalletStatus}
    />
  ) : (
    <Presenter
      chargedPoint={chargedPoint}
      myPoint={myPoint}
      navigateToHome={navigateToHome}
      getPointWalletStatus={getPointWalletStatus}
    />
  );
};

export { Container as CompletionPage };
