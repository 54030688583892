import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getAvailableMyGiftsConnectionThunk } from '/@/store/api/queries/getMyGiftsConnection';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { STATUS } from '/@/store/api/constants';
import { selectChangedAtGroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';

const Container: React.VFC<Record<string, never>> = () => {
  const groupedAvailableMyGifts = useAppSelector(
    selectChangedAtGroupedAvailableMyGifts,
  );
  const availableMyGiftsConnectionPageInfo = useAppSelector(
    (state) => state.app.availableMyGiftsConnection.pageInfo,
  );
  const getAvailableMyGiftsConnectionStatus = useAppSelector(
    (state) => state.app.availableMyGiftsConnection.status,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAvailableMyGiftsConnectionThunk()).catch(() => {});
  }, [dispatch]);

  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getAvailableMyGiftsConnectionStatus !== STATUS.LOADING &&
      availableMyGiftsConnectionPageInfo?.hasNextPage
    ) {
      dispatch(
        getAvailableMyGiftsConnectionThunk(
          availableMyGiftsConnectionPageInfo.endCursor,
        ),
      ).catch(() => {});
    }
  }, [
    dispatch,
    isIntersect,
    getAvailableMyGiftsConnectionStatus,
    groupedAvailableMyGifts,
    availableMyGiftsConnectionPageInfo,
  ]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      groupedAvailableMyGifts={groupedAvailableMyGifts}
      infiniteScrollTarget={infiniteScrollTarget}
      getAvailableMyGiftsConnectionStatus={getAvailableMyGiftsConnectionStatus}
    />
  ) : (
    <Presenter
      groupedAvailableMyGifts={groupedAvailableMyGifts}
      infiniteScrollTarget={infiniteScrollTarget}
      getAvailableMyGiftsConnectionStatus={getAvailableMyGiftsConnectionStatus}
    />
  );
};

export { Container as AvailableMyGifts };
