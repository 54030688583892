import React from 'react';
import { RecommendedContents } from './recommendedContents/Index';
import { ExchangeableContents } from './exchangeableContents/Index';
import { Button, Checkbox } from '@mui/material';
import { Box, styled } from '@mui/system';
import { DisplayOnlyExchangeableBrandsState } from '/@/store/app/displayOnlyExchangeableBrands';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../typography/Index';
import { neoTheme } from '/@/utils/theme';

const StyledSwitchDisplayOnlyExchangeableContainer = styled(Button)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 0,
  width: '100%',
  color: neoTheme.palette.secondary.dark,
});
const StyledSectionTitleContainer = styled('div')(({ theme }) => ({
  color: neoTheme.palette.secondary.dark,
  '&:not(:first-child)': {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  isDisplayOnlyExchangeableBrands: DisplayOnlyExchangeableBrandsState;
  handleDisplayOnlyExchangeable: () => void;
};
const Presenter: React.VFC<Props> = ({
  isDisplayOnlyExchangeableBrands,
  handleDisplayOnlyExchangeable,
}) => (
  <>
    <StyledSwitchDisplayOnlyExchangeableContainer
      onClick={handleDisplayOnlyExchangeable}
    >
      <BaseTypography fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.MD} isBold>
        チケットを選択
      </BaseTypography>
      <Box sx={{ display: 'flex' }}>
        <Checkbox
          checked={isDisplayOnlyExchangeableBrands}
          data-cy="checkBox-displayOnlyExchangeable"
          color="primary"
          style={{ padding: 0, marginRight: 4 }}
        />
        <BaseTypography fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.SM} isBold={false}>
          交換可能なチケットに絞る
        </BaseTypography>
      </Box>
    </StyledSwitchDisplayOnlyExchangeableContainer>
    <StyledSectionTitleContainer>
      <BaseTypography fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.MD} isBold>
        今交換するとお得なチケット
      </BaseTypography>
    </StyledSectionTitleContainer>
    <RecommendedContents />
    <StyledSectionTitleContainer>
      <BaseTypography fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.MD} isBold>
        交換可能なチケット
      </BaseTypography>
    </StyledSectionTitleContainer>
    <ExchangeableContents />
  </>
);

export { Presenter };
