import React from 'react';
import {
  SelectedGiftsAvailabilityTabState,
  GIFTS_AVAILABILITY,
} from '/@/store/ui/selectedGiftsAvailabilityTab';
import { AvailableMyGifts } from '/@/components/page/giftsPage/availableMyGifts/Index';
import { UnavailableMyGifts } from '/@/components/page/giftsPage/unavailableMyGifts/Index';
import { SelectTab } from '/@/components/shared/selectTab/Index';

type Props = {
  selectedGiftsAvailabilityTab: SelectedGiftsAvailabilityTabState;
  handleGiftsAvailabilityTab: (
    selectedGiftsAvailabilityTab: SelectedGiftsAvailabilityTabState,
  ) => void;
};
const Presenter: React.VFC<Props> = ({
  selectedGiftsAvailabilityTab,
  handleGiftsAvailabilityTab,
}) => (
  <>
    <SelectTab
      selectedTab={selectedGiftsAvailabilityTab}
      handleChangeTab={(value: string) => handleGiftsAvailabilityTab(value as SelectedGiftsAvailabilityTabState)}
      tabs={[
        { label: '利用可能', value: GIFTS_AVAILABILITY.AVAILABLE, dataCyName: 'selectAvailableGiftsTab' },
        { label: '利用済み・期限切れ', value: GIFTS_AVAILABILITY.UNAVAILABLE, dataCyName: 'selectUnavailableGiftsTab' },
      ]}
    />
    {selectedGiftsAvailabilityTab === GIFTS_AVAILABILITY.AVAILABLE ? (
      <AvailableMyGifts />
    ) : (
      <UnavailableMyGifts />
    )}
  </>
);

export { Presenter };
