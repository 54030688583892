import React, { useState } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';

const Container: React.VFC<Record<string, never>> = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      isOpenModal={isOpenModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
    />
  ) : (
    <Presenter
      isOpenModal={isOpenModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
    />
  );
};
export { Container as BenefitOfPointChargeModalWithButton };
