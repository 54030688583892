import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { BenefitOfPointChargeModalWithButton } from '/@/components/shared/benefitOfPointCharge/modalWithButton/Index';
import { POINT_FONT_SIZE, Points } from '../points/Index';
import { neoTheme } from '/@/utils/theme';

export const POINT_SECTION_HEIGHT = 64;

const StyledContainer = styled('div')(
  ({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    height: `${POINT_SECTION_HEIGHT}px`,
    borderRadius: theme.spacing(),
    backgroundColor: neoTheme.palette.background.contrast,
  }),
);
const StyledHeading = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '-webkit-fill-available',
});

type Props = {
  points: number | undefined;
  benefitOfPointChargeModalVisible: boolean;
};

const Presenter: React.VFC<Props> = ({
  points,
  benefitOfPointChargeModalVisible,
}) => (
  <StyledContainer>
    <StyledHeading container alignItems="center">
      <Points points={points} fontSize={POINT_FONT_SIZE.MD} />
      {benefitOfPointChargeModalVisible && <BenefitOfPointChargeModalWithButton />}
    </StyledHeading>
  </StyledContainer>
);

export { Presenter };
