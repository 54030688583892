import React from 'react';
import {
  Button,
  ButtonProps,
  Card,
  Divider,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { formatToEndDateJa } from '/@/utils/formatDate';
import { PointChargeModal } from '/@/components/shared/modal/pointChargeModal/Index';
import { GetMyGiftQuery } from '/@/api/graphql/internalApi/types';
import { neoTheme } from '/@/utils/theme';

const StyledMyGiftCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));
const StyledLogoAndTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2) + ' !important',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: '100%',
  width: '100%',
}));
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: '60px',
  height: '60px',
  borderRadius: theme.spacing(1),
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  width: '-webkit-fill-available',
}));
const StyledItemName = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.4rem',
  fontWeight: 'bold',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
});
const StyledPointChargeableText = styled(Typography)({
  display: 'inline-block',
  marginLeft: '0.5rem',
  fontSize: '0.75rem',
  lineHeight: '1.5',
  fontWeight: 'bold',
  color: '#1DABD6',
});
const StyledNotChargeableText = styled(Typography)({
  display: 'inline-block',
  marginLeft: '0.5rem',
  fontSize: '0.75rem',
  lineHeight: '1.5',
  fontWeight: 'bold',
  color: neoTheme.palette.grey[700],
});
const StyledEndAt = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.75rem',
  lineHeight: '1.5',
  color: neoTheme.palette.grey[700],
}));
const StyledButtonsContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));
type StyledPointChargeButtonProps = ButtonProps & { isPointMergeable: boolean };
const StyledPointChargeButton = styled(Button)<StyledPointChargeButtonProps>(
  ({ isPointMergeable, theme }) => ({
    width: '100%',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 'bold',
    color: neoTheme.palette.common.white,
    backgroundColor: neoTheme.palette.common.black,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(4),
    boxShadow: 'none',
    ...(!isPointMergeable && {
      color: neoTheme.palette.grey[700] + ' !important',
      backgroundColor: neoTheme.palette.grey[400] + ' !important',
    }),
  }),
);
type StyledUsingGiftButtonProps = ButtonProps & { to: string };
const StyledUsingGiftButton = styled(Button)<StyledUsingGiftButtonProps>(
  ({ theme }) => ({
    width: '100%',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 'bold',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(4),
    backgroundColor: neoTheme.palette.common.white,
    color: neoTheme.palette.secondary.dark,
    border: `2px solid ${neoTheme.palette.secondary.light}`,
    boxShadow: 'none',
  }),
);

type Props = {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  myGift: GetMyGiftQuery['myGift'];
  expiredAtLabel: string;
};
const Presenter: React.VFC<Props> = ({
  isOpenModal,
  handleOpenModal,
  handleCloseModal,
  myGift,
  expiredAtLabel,
}) => (
  <StyledMyGiftCard variant="outlined">
    <StyledLogoAndTitle>
      <StyledLogoContainer>
        <StyledLogo src={myGift.imageUrl} alt={myGift.contentName} />
      </StyledLogoContainer>
      <StyledRightContent>
        <StyledItemName>
          {myGift.contentName}
          {myGift.pointMergeable ? (
            <StyledPointChargeableText>
              ポイントに移行可能
            </StyledPointChargeableText>
          ) : (
            <StyledNotChargeableText>ポイント移行不可</StyledNotChargeableText>
          )}
        </StyledItemName>
        <StyledEndAt>
          {expiredAtLabel +
            '期限 ' +
            (myGift.expiredAt
              ? formatToEndDateJa(myGift.expiredAt)
              : '取得中...')}
        </StyledEndAt>
      </StyledRightContent>
    </StyledLogoAndTitle>
    <Divider />
    <StyledButtonsContainer container direction="column" alignContent="center">
      <StyledPointChargeButton
        variant="contained"
        disableElevation
        isPointMergeable={myGift.pointMergeable}
        onClick={handleOpenModal}
        disabled={myGift.remainingPoint === 0}
        data-cy="pointChargeButton"
        data-gtm-click="pointPage-chargePage-pointChargeSection-chargeableGift-pointChargeButton"
      >
        {myGift.pointMergeable && myGift.remainingPoint > 0
          ? 'ギフトをポイントに移行する'
          : '利用可能なポイントはありません'}
      </StyledPointChargeButton>
      <StyledUsingGiftButton
        variant="contained"
        disableElevation
        component={Link}
        to={myGift.giftUrl}
        data-cy="usingGiftButton"
      >
        ギフトを利用する
      </StyledUsingGiftButton>
    </StyledButtonsContainer>
    {isOpenModal && <PointChargeModal handleClose={handleCloseModal} />}
  </StyledMyGiftCard>
);

export { Presenter };
