import React from 'react';
import { MyGiftBase } from '/@/store/app/shared/types';
import { MyGiftSkelton } from './myGiftSkelton/Index';
import { calcMyGiftUsageStatus } from '/@/utils/calcMyGiftUsageStatus';
import expiredAtLabel from '/@/utils/expiredAtLabel';
import { MyGiftDisplaySize } from './Index';

type OwnProps = {
  myGift: MyGiftBase;
  myGiftDisplaySize?: MyGiftDisplaySize;
  isHiddenMergeableText?: boolean;
};

const Presenter: React.VFC<OwnProps> = ({
  myGift,
  myGiftDisplaySize,
  isHiddenMergeableText,
}) => {
  const label = expiredAtLabel(myGift.giftType);
  return (
    <div data-cy={`myGift-${myGift.urlCode}`}>
      <MyGiftSkelton
        myGift={myGift}
        expiredAtLabel={label}
        usageStatus={calcMyGiftUsageStatus(myGift)}
        myGiftDisplaySize={myGiftDisplaySize}
        isHiddenMergeableText={isHiddenMergeableText}
      />
    </div>
  );
};

export { Presenter };
