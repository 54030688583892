import React from 'react';
import {
  Typography,
  Container,
  Box,
  Grid2 as Grid,
  Button,
  ButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiftsAvailabilityTabs } from '/@/components/page/giftsPage/giftsAvailablityTabs/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { ExpiringMyGifts } from '/@/components/page/giftsPage/expiringMyGifts/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { PointSection } from './pointSection/Index';
import { neoTheme } from '/@/utils/theme';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../shared/typography/Index';

const StyledBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.white,
}));
const StyledBaseBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
}));
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    borderRadius: theme.spacing(2),
  },
  backgroundColor: theme.palette.background.default,
  padding: 0 + ' !important',
}));
type StyledButtonProps = ButtonProps & { to: string };
const StyledGiftsEditPageButton = styled(Button)<StyledButtonProps>(
  ({ theme }) => ({
    minWidth: '0',
    color: theme.palette.primary.dark,
    '&.MuiButton-contained': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.hint,
        color: theme.palette.secondary.main,
      },
    },
  }),
);
const StyledGiftsEditPageButtonLabel = styled(Typography)({
  color: neoTheme.palette.common.black,
  fontSize: '0.75rem',
  lineHeight: '1.5',
  textDecoration: 'underline',
});
const StyledExpiringMyGiftsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}));
const StyledGiftsAvailabilityTabsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

type Props = {
  isGiftsEditPageButtonDisabled: boolean;
};
const Presenter: React.VFC<Props> = ({ isGiftsEditPageButtonDisabled }) => (
  <MainLayout>
    <StyledBackground>
      <PointSection />
      <StyledBaseBox>
        <StyledContainer maxWidth="sm">
          <Grid container alignItems="center" justifyContent="space-between">
            <BaseTypography fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.MD} isBold>
              マイギフト
            </BaseTypography>
            <StyledGiftsEditPageButton
              disabled={isGiftsEditPageButtonDisabled}
              variant="text"
              disableElevation
              component={Link}
              to={PATHS.GIFTS_EDIT}
              data-cy="giftsEditPageButton"
            >
              <StyledGiftsEditPageButtonLabel color="inherit" variant="caption">
                利用済みにする
              </StyledGiftsEditPageButtonLabel>
            </StyledGiftsEditPageButton>
          </Grid>
          <StyledExpiringMyGiftsContainer>
            <ExpiringMyGifts />
          </StyledExpiringMyGiftsContainer>
          <StyledGiftsAvailabilityTabsContainer>
            <GiftsAvailabilityTabs />
          </StyledGiftsAvailabilityTabsContainer>
        </StyledContainer>
      </StyledBaseBox>
    </StyledBackground>
  </MainLayout>
);

export { Presenter };
