import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { formatToEndDateJa } from '/@/utils/formatDate';
import {
  MyGiftUsageStatus,
  MY_GIFT_USAGE_STATUS,
} from '/@/utils/calcMyGiftUsageStatus';
import { PATHS } from '/@/routes/paths';
import { MyGiftBase } from '/@/store/app/shared/types';
import { useAppSelector } from '/@/store';
import { MYGIFT_DISPLAY_SIZE, MyGiftDisplaySize } from '../Index';
type Props = {
  myGift: MyGiftBase;
  expiredAtLabel: string;
  usageStatus: MyGiftUsageStatus;
  myGiftDisplaySize?: MyGiftDisplaySize;
  isHiddenMergeableText?: boolean;
};

const Container: React.VFC<Props> = ({
  myGift,
  expiredAtLabel,
  usageStatus,
  myGiftDisplaySize = MYGIFT_DISPLAY_SIZE.MD,
  isHiddenMergeableText = false,
}) => {
  const navigate = useNavigate();
  const expirationText =
    usageStatus === MY_GIFT_USAGE_STATUS.DISCARDED
      ? '利用済み'
      : usageStatus === MY_GIFT_USAGE_STATUS.EXPIRED
        ? `${expiredAtLabel}期限切れ`
        : `${expiredAtLabel}期限 ${myGift.expiredAt !== null ? formatToEndDateJa(myGift.expiredAt) : '...取得中'}`;

  const handleClick = () => {
    if (myGift.giftUrl.startsWith('/')) {
      navigate(myGift.giftUrl);
    } else if (myGift.pointMergeable) {
      navigate(`${PATHS.POINT_CHARGE}?my_gift_url_code=${myGift.urlCode}`);
    } else {
      window.location.href = myGift.giftUrl;
    }
  };

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      name={myGift.contentName}
      imageUrl={myGift.imageUrl}
      expirationText={expirationText}
      usageStatus={usageStatus}
      pointMergeable={myGift.pointMergeable}
      handleClick={handleClick}
      myGiftDisplaySize={myGiftDisplaySize}
      isHiddenMergeableText={isHiddenMergeableText}
    />
  ) : (
    <Presenter
      name={myGift.contentName}
      imageUrl={myGift.imageUrl}
      expirationText={expirationText}
      usageStatus={usageStatus}
      pointMergeable={myGift.pointMergeable}
      handleClick={handleClick}
    />
  );
};

export { Container as MyGiftSkelton };
