import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';

const Container: React.VFC<{
  headerTitle: string;
  handleNavigateBack: () => void;
}> = ({ headerTitle, handleNavigateBack }) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      headerTitle={headerTitle}
      handleNavigateBack={handleNavigateBack}
    />
  ) : (
    <Presenter
      headerTitle={headerTitle}
      handleNavigateBack={handleNavigateBack}
    />
  );
};

export { Container as HeaderTitle };
