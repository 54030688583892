import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import topImage from '/@/assets/page/lpPage/lpMergeToPointPage/lp-hero-top-image.png';
import { LoginButton } from '/@/components/shared/loginButton/Index';
import { PATHS } from '/@/routes/paths';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 3),
}));
const StyledSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  lineHeight: '1',
  marginBottom: theme.spacing(0.5),
  textAlign: 'center',
}));
const StyledMainTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  lineHeight: '1.3',
  textAlign: 'center',
}));
const StyledTopImage = styled('img')(({ theme }) => ({
  display: 'block',
  width: '85%',
  margin: `${theme.spacing(3)} auto 0`,
}));
const StyledMainText = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  lineHeight: '1.4',
  textAlign: 'left',
});
const StyledLoginButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledConvertToPointNote = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  display: 'inline-block',
  width: '100%',
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontSize: '0.75rem',
}));

type Props = {
  url: string | null;
  origin: OriginEnum | null;
  referer: string | null;
  convertToPoint: boolean | null;
  destination: DestinationEnum | null;
};

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  referer,
  convertToPoint,
  destination,
}) => (
  <StyledContainer data-cy="signInPageHeroSection">
    <Grid container direction="column" alignContent="center">
      <Grid size="grow">
        <StyledSubTitle variant="h3">えらべるPayは</StyledSubTitle>
        <StyledMainTitle variant="h1">
          gift wallet ポイントへ
          <br />
          まとめると便利！
        </StyledMainTitle>
      </Grid>
      <Grid size="grow">
        <StyledTopImage src={topImage} alt="giftWalletTopimage" />
        <StyledMainText variant="body1">
          gift wallet に移行で、ポイントの還元やポイントのまとめて利用など、便利なポイントの活用ができます。
        </StyledMainText>
      </Grid>
      <Grid size="grow">
        <StyledLoginButtonContainer>
          <LoginButton
            url={url}
            origin={origin}
            referer={referer}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={null}
            flow={PATHS.LP_MERGE_TO_POINT}
          >
            ギフトをポイントに移行する
          </LoginButton>
          <StyledConvertToPointNote variant="caption">
            ※ポイントは残高全てが移行されます。ご注意ください。
          </StyledConvertToPointNote>
        </StyledLoginButtonContainer>
      </Grid>
    </Grid>
  </StyledContainer>
);

export { Presenter };
