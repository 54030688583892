import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { neoTheme } from '/@/utils/theme';

export const FOOTER_HEIGHT = 138;
const StyledFooter = styled('footer')(({ theme }) => ({
  padding: theme.spacing(4, 2),
  margin: '0 auto',
  backgroundColor: neoTheme.palette.common.white,
  width: '100%',
  maxWidth: '600px',
  height: `${FOOTER_HEIGHT}px`,
  minHeight: `${FOOTER_HEIGHT}px`,
  display: 'flex',
  justifyContent: 'space-between',
  // PC表示の場合はpaddingを調整
}));
const StyledItemContainer = styled('div')(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '1.5',
  '& > * + *': {
    marginTop: theme.spacing(1.25),
  },
}));
const StyledATag = styled('a')({
  textDecoration: 'none',
  display: 'block',
});
const StyledLink = styled(Link)({
  textDecoration: 'none',
  display: 'block',
});
const StyledLinkTitle = styled(Typography)({
  color: neoTheme.palette.secondary.dark,
});
const StyledCopyright = styled(Typography)({
  display: 'block',
  color: neoTheme.palette.secondary.main,
});

const Presenter: React.VFC<Record<string, never>> = () => (
  <StyledFooter data-cy="footer">
    <StyledItemContainer>
      <StyledATag
        href="https://giftee.co.jp/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledLinkTitle variant="caption">運営会社</StyledLinkTitle>
      </StyledATag>
      <StyledLink to={PATHS.FREQUENTLY_ASKED_QUESTIONS}>
        <StyledLinkTitle variant="caption">よくあるご質問</StyledLinkTitle>
      </StyledLink>
      <StyledLink to={PATHS.TERMS_OF_SERVICE}>
        <StyledLinkTitle variant="caption">サービス利用規約</StyledLinkTitle>
      </StyledLink>
    </StyledItemContainer>
    <StyledCopyright>©︎giftee, inc.</StyledCopyright>
  </StyledFooter>
);

export { Presenter };
