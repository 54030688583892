import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '/@/store';
import { STATUS } from '/@/store/api/constants';
import { PATHS } from '/@/routes/paths';

const Container: React.VFC<Record<string, never>> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pointLogStatus = location?.state?.pointLogStatus || null;
  const exchangedGifts = useAppSelector((state) => state.app.exchangedGifts);
  const createPointExchangeStatus = useAppSelector(
    (state) => state.api.createPointExchange.status,
  );

  useEffect(() => {
    if (
      createPointExchangeStatus !== STATUS.LOADING &&
      exchangedGifts === null
    ) {
      // MEMO: ブラウザバックやリロードした際を考慮し、storeにデータがない場合はHOMEへリダイレクト
      navigate(PATHS.HOME);
    }
  }, [createPointExchangeStatus, exchangedGifts, navigate]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter pointLogStatus={pointLogStatus} />
  ) : (
    <Presenter pointLogStatus={pointLogStatus} />
  );
};

export { Container as CompletionPage };
