import React from 'react';
import {
  Typography,
  Grid2 as Grid,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import ArrowRightInCircleIcon from '/@/assets/shared/icon/arrow-right-in-circle-icon.svg';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { formatToDateJa } from '/@/utils/formatDate';
import type { PointWalletState } from '/@/store/app/pointWallet';
import { POINT_FONT_SIZE, Points } from '/@/components/shared/points/Index';
import { Card } from '/@/components/shared/card/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';

export type PointWallet = NonNullable<PointWalletState>;

const StyledPointDisplayArea = styled(Grid)({
  height: '2rem',
});
// 表示調整用
const StyledLoader = styled('div')(({ theme }) => ({
  marginTop: `-${theme.spacing(2)}`,
  transform: 'scale(0.5)',
}));
const StyledPointAvailablePeriodContent = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.grey[700],
  fontSize: '0.75rem',
}));
const StyledPointAvailablePeriod = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontSize: '0.875rem',
}));
const StyledPointBrandsPageButton = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(0),
}));
const StyledPointLogsButtonWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: theme.spacing(4),
  width: theme.spacing(4),
}));
const StyledPointPointLogsPageButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  height: '70%',
  width: '70%',
}));
const StyledArrowRightInCircleIcon = styled('img')({
  height: '24px',
  width: '24px',
});

type Props = {
  pointWallet: PointWallet | null;
  getPointWalletStatus: Status;
  handleNavigatePointBrands: () => void;
  handleNavigatePointLogs: () => void;
};

const Presenter: React.VFC<Props> = ({
  pointWallet,
  getPointWalletStatus,
  handleNavigatePointBrands,
  handleNavigatePointLogs,
}) => (
  <Card>
    <Grid container justifyContent="space-between">
      <StyledPointDisplayArea>
        {getPointWalletStatus === STATUS.LOADING ? (
          <StyledLoader>
            <PartialLoader />
          </StyledLoader>
        ) : (
          <Grid container direction="row" alignItems="baseline">
            <Points points={pointWallet?.point} fontSize={POINT_FONT_SIZE.XL} />
          </Grid>
        )}
      </StyledPointDisplayArea>
      <StyledPointLogsButtonWrapper>
        <StyledPointPointLogsPageButton
          color="secondary"
          onClick={handleNavigatePointLogs}
          data-cy="handleNavigatePointLogsButton"
          data-gtm-click="homePage-pointSection-pointCard-handleNavigatePointLogsButton"
        >
          <StyledArrowRightInCircleIcon src={ArrowRightInCircleIcon} />
        </StyledPointPointLogsPageButton>
      </StyledPointLogsButtonWrapper>
    </Grid>
    {pointWallet?.point !== undefined && pointWallet.point > 0 && (
      <StyledPointAvailablePeriodContent variant="body2">
        直近のポイント有効期限
        <StyledPointAvailablePeriod>
          {pointWallet?.nearestAvailableWithdrawableUntilAt
            ? formatToDateJa(pointWallet.nearestAvailableWithdrawableUntilAt)
            : '-'}
        </StyledPointAvailablePeriod>
      </StyledPointAvailablePeriodContent>
    )}
    <StyledPointBrandsPageButton>
      <BaseButton
        onClick={handleNavigatePointBrands}
        disabled={!pointWallet?.point}
        data-cy="handleNavigatePointBrandsButton"
        data-gtm-click="homePage-pointSection-pointCard-handleNavigatePointBrandsButton"
      >
        交換にすすむ
      </BaseButton>
    </StyledPointBrandsPageButton>
  </Card >
);

export { Presenter };
