import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { Modal } from '/@/components/shared/modal/Index';
import { POINT_FONT_SIZE, Points } from '../../points/Index';
import { neoTheme } from '/@/utils/theme';
import { BaseButton } from '../../button/baseButton/Index';

const StyledMessageText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1rem',
  lineHeight: '1.3375rem',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
});
const StyledNoteText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  textAlign: 'center',
  fontSize: '0.75rem',
  lineHeight: '1.5',
  color: neoTheme.palette.grey[700],
}));
const StyledPointsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: neoTheme.palette.background.contrast,
  borderRadius: theme.spacing(2),
}));
const StyledPointTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: '0.75rem',
  fontWeight: 'bold',
  color: theme.palette.grey[700],
}));
const StyledExchangeSubmitButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.875),
  fontWeight: 'bold',
  lineHeight: '1',
  borderRadius: theme.spacing(2.75),
  boxShadow: 'none',
  backgroundColor: neoTheme.palette.common.black,
}));
const StyledCancelButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

type Props = {
  cancelCharge: () => void;
  handleSubmit: () => void;
  pointAmount: number | undefined;
  isLoading: boolean;
};
const Presenter: React.VFC<Props> = ({
  cancelCharge,
  handleSubmit,
  pointAmount,
  isLoading,
}) => (
  <Modal handleClose={cancelCharge} data-cy="pointChargeModal">
    <Grid container direction="column" justifyContent="center">
      <StyledMessageText>
        選択したギフトを
        <br />
        ポイント移行しますか？
      </StyledMessageText>
      <StyledNoteText>
        ※ ポイント移行したギフトは元に戻せません。
      </StyledNoteText>
      <StyledPointsContainer>
        <StyledPointTitle>移行されるポイント</StyledPointTitle>
        <Points points={pointAmount} fontSize={POINT_FONT_SIZE.LG} />
      </StyledPointsContainer>
      <StyledExchangeSubmitButton
        variant="contained"
        color="primary"
        loading={isLoading}
        onClick={handleSubmit}
        disabled={!pointAmount || isLoading}
        data-gtm-click="shared-modal-pointChargeModal-submitButton"
      >
        移行する
      </StyledExchangeSubmitButton>
      <StyledCancelButtonContainer>
        <BaseButton
          isTransparent
          onClick={cancelCharge}
          data-gtm-click="shared-modal-pointChargeModal-cancelButton"
        >
          キャンセル
        </BaseButton>
      </StyledCancelButtonContainer>
    </Grid>
  </Modal>
);

export { Presenter };
