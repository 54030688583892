import React from 'react';
import {
  Typography,
  Grid2 as Grid,
  Container,
  Button,
  ButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import pointChargeIcon from '/@/assets/shared/icon/saved-gift-icon.svg';
import { ItemDetailModal } from '/@/components/shared/pointItemContents/itemDetailModal/Index';
import { POINT_FONT_SIZE, Points } from '/@/components/shared/points/Index';
import { Status } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';

const StyledPointChargedContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
  padding: theme.spacing(4, 2),
}));
const StyledPointChargeIcon = styled('img')({
  margin: 'auto',
});
const StyledPointChargedTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  marginTop: theme.spacing(3),
}));
const StyledPointBalanceText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.grey[700],
  marginTop: theme.spacing(3),
}));
const StyledPointDisplayArea = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  textAlign: 'center',
}));
const StyledNavigateToHomeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  fontWeight: 'bold',
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(4),
}));
// const StyledPopulerItemsSection = styled(Button)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

type Props = {
  chargedPoint: number | undefined;
  myPoint: number | undefined;
  navigateToHome: () => void;
  getPointWalletStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  chargedPoint,
  myPoint,
  navigateToHome,
  getPointWalletStatus,
}) => (
  <MainLayout>
    <StyledPointChargedContainer maxWidth="sm">
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <StyledPointChargeIcon
          src={pointChargeIcon}
          alt="シリアルコードのポイントを獲得しました"
          width="100"
          height="100"
          data-cy="pointChargeIcon"
        />
        <StyledPointChargedTitle>
          {chargedPoint ?? '-'}ポイント獲得しました
        </StyledPointChargedTitle>
        <StyledPointBalanceText>ポイント残高</StyledPointBalanceText>
        <StyledPointDisplayArea>
          {getPointWalletStatus === 'loading' ? (
            <PartialLoader />
          ) : (
            <Points points={myPoint} fontSize={POINT_FONT_SIZE.XL} />
          )}
        </StyledPointDisplayArea>
        <StyledNavigateToHomeButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={navigateToHome}
          data-cy="navigateToHomeButton"
          data-gtm-click="pointPage-chargePage-completionPage-navigateToHomeButton"
        >
          マイギフトへ戻る
        </StyledNavigateToHomeButton>
      </Grid>
    </StyledPointChargedContainer>
    {/* データが貯まるまで一旦非表示. cf: https://giftee-inc.slack.com/archives/C06E76T0J86/p1724049106246419?thread_ts=1723773614.435139&cid=C06E76T0J86 */}
    {/* <StyledPopulerItemsSection maxWidth='sm'>
      <PopulerItemsSection />
    </StyledPopulerItemsSection> */}
    <ItemDetailModal />
  </MainLayout>
);

export { Presenter };
