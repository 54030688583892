import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { useNavigate } from 'react-router-dom';
import { itemSearchConditionActions } from '/@/store/app/itemSearchCondition';
import { PATHS } from '/@/routes/paths';
import { ItemSearchConditionState } from '/@/store/app/itemSearchCondition/reducers';
import { useDataLayer } from '/@/common/hooks/useDataLayer';

type OwnProps = {
  headerTitle: string;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
  isConditionOpened: ConditionOpenedType;
  handleOpenCondition: (type: ConditionOpenedType) => void;
};

export type ConditionOpenedType = 'brand' | 'point' | null;

const Container: React.VFC<OwnProps> = (OwnProps) => {
  const dispatch = useAppDispatch();
  const itemSearchCondition = useAppSelector(
    (state) => state.app.itemSearchCondition,
  );
  const brand = useAppSelector((state) => state.app.brand);
  const exchangeableBrands = useAppSelector(
    (state) => state.app.exchangeableBrandsConnection.brands,
  );
  const [conditions, setConditions] = useState<ItemSearchConditionState>(null);
  const [sideMenuTitle, setSideMenuTitle] = useState<string>('検索条件');
  const [selectedBrandName, setSelectedBrandName] = useState<string | null>(
    null,
  );
  const { pushSearchItemsEvent } = useDataLayer();
  const myPoints = useAppSelector((state) => state.app.pointWallet?.point);

  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate(-1); // 1つ前の画面に戻る
  };

  const setMinPointCondition = (minPoint: number) => {
    setConditions({
      ...conditions,
      minPoint: minPoint,
    });
  };

  const setMaxPointCondition = (maxPoint: number) => {
    setConditions({
      ...conditions,
      maxPoint: maxPoint,
    });
  };

  const handleClearBrandCondition = () => {
    setConditions({
      ...conditions,
      brandUid: undefined,
    });
    setSelectedBrandName(null);
  };

  const handleClearCondition = () => {
    setConditions({
      brandUid: undefined,
      minPoint: 0,
      maxPoint: 0,
    });
    setSelectedBrandName(null);
  };

  const searchItems = () => {
    if (
      conditions?.minPoint &&
      conditions?.maxPoint &&
      conditions.minPoint > conditions.maxPoint
    ) {
      return null;
    }

    dispatch(
      itemSearchConditionActions.setItemSearchCondition({
        brandUid: conditions?.brandUid,
        minPoint:
          conditions?.minPoint && conditions.minPoint > 0
            ? conditions.minPoint
            : undefined,
        maxPoint:
          conditions?.maxPoint && conditions.maxPoint > 0
            ? conditions.maxPoint
            : undefined,
      }),
    );
    OwnProps.handleToggleMenu();

    const pointPrams: { minPoint?: string; maxPoint?: string } = {};
    if (conditions?.minPoint && conditions.minPoint > 0)
      pointPrams.minPoint = conditions.minPoint.toString();
    if (conditions?.maxPoint && conditions.maxPoint > 0)
      pointPrams.maxPoint = conditions.maxPoint.toString();

    const queryParams =
      conditions?.minPoint || conditions?.maxPoint
        ? new URLSearchParams(pointPrams)
        : '';

    pushSearchItemsEvent({
      brandUid: conditions?.brandUid ?? null,
      brandName: selectedBrandName ?? null,
      minPoint: conditions?.minPoint ?? null,
      maxPoint: conditions?.maxPoint ?? null,
      possessionPoints: myPoints ?? null,
      customData: {
        brandUid: conditions?.brandUid ?? null,
        brandName: selectedBrandName ?? null,
        minPoint: conditions?.minPoint ?? null,
        maxPoint: conditions?.maxPoint ?? null,
        possessionPoints: myPoints ?? null,
      },
    });

    if (conditions?.brandUid) {
      const brandItemPath = PATHS.BRAND_ITEMS.replace(
        ':uid',
        conditions?.brandUid,
      );
      return navigate(`${brandItemPath}${'?' + queryParams.toString()}`);
    } else {
      return navigate(`${PATHS.ITEMS}${'?' + queryParams.toString()}`);
    }
  };

  // ブランド条件初期値
  useEffect(() => {
    setSelectedBrandName(brand?.name ?? null);
  }, [brand]);

  // storeからの検索条件復元
  useEffect(() => {
    setConditions(itemSearchCondition);
  }, [itemSearchCondition]);

  // メニュータイトル変更
  useEffect(() => {
    setSideMenuTitle(
      OwnProps.isConditionOpened === 'brand' ? 'ブランド' : '検索条件',
    );
  }, [OwnProps.isConditionOpened]);

  // ブランド条件変更時
  useEffect(() => {
    if (conditions?.brandUid && exchangeableBrands) {
      const selectedBrand = exchangeableBrands.find(
        (brand) => brand?.uid === conditions?.brandUid,
      );
      setSelectedBrandName(selectedBrand?.name ?? null);
    }
  }, [conditions?.brandUid, exchangeableBrands]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      {...OwnProps}
      handleNavigateBack={handleNavigateBack}
      selectedBrandName={selectedBrandName}
      conditions={conditions}
      setConditions={setConditions}
      searchItems={searchItems}
      handleClearBrandCondition={handleClearBrandCondition}
      handleClearCondition={handleClearCondition}
      setMinPointCondition={setMinPointCondition}
      setMaxPointCondition={setMaxPointCondition}
    />
  ) : (
    <Presenter
      {...OwnProps}
      sideMenuTitle={sideMenuTitle}
      handleNavigateBack={handleNavigateBack}
      selectedBrandName={selectedBrandName}
      conditions={conditions}
      setConditions={setConditions}
      searchItems={searchItems}
      handleClearBrandCondition={handleClearBrandCondition}
      handleClearCondition={handleClearCondition}
      setMinPointCondition={setMinPointCondition}
      setMaxPointCondition={setMaxPointCondition}
    />
  );
};
export { Container as SearchHeader };
