import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';

const Container: React.VFC<{
  unreadBadgeVisible: boolean;
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
}> = ({ unreadBadgeVisible, handleToggleMenu, handleClickGlobalMenu }) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      unreadBadgeVisible={unreadBadgeVisible}
      handleToggleMenu={handleToggleMenu}
      handleClickGlobalMenu={handleClickGlobalMenu}
    />
  ) : (
    <Presenter
      unreadBadgeVisible={unreadBadgeVisible}
      handleToggleMenu={handleToggleMenu}
      handleClickGlobalMenu={handleClickGlobalMenu}
    />
  );
};

export { Container as HeaderGrobalMenu };
