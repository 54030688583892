import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import { PATHS } from '/@/routes/paths';
import giftWalletLogo from '/@/assets/shared/icon/giftee-wallet-logo-with-title.svg';

const StyledGiftWalletLogoLink = styled(RouterLink)(({ theme }) => ({
  height: theme.spacing(3),
}));
const StyledGiftWalletLogo = styled('img')(({ theme }) => ({
  height: theme.spacing(3),
}));

type Props = {
  isLogoClickable: boolean;
};

const Presenter: React.VFC<Props> = ({ isLogoClickable }) =>
  isLogoClickable ? (
    <StyledGiftWalletLogoLink to={PATHS.ROOT} data-cy="clickableGiftWalletLogo">
      <img height={24} src={giftWalletLogo} alt="clickableGiftWalletLogo" />
    </StyledGiftWalletLogoLink>
  ) : (
    <StyledGiftWalletLogo
      height={26}
      src={giftWalletLogo}
      alt="unclickableGiftWalletLogo"
      data-cy="unclickableGiftWalletLogo"
    />
  );

export { Presenter };
