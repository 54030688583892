import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { BenefitOfPointChargeModalWithButton } from '/@/components/shared/benefitOfPointCharge/modalWithButton/Index';
import { POINT_FONT_SIZE, Points } from '../points/Index';

export const POINT_SECTION_HEIGHT = 48;
export const POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT = 95;

type StyledContainerProps = { isBenefitOfPointChargeModalVisible: boolean };
const StyledContainer = styled('div')<StyledContainerProps>(
  ({ isBenefitOfPointChargeModalVisible, theme }) => ({
    padding: theme.spacing(1.5, 2),
    height: isBenefitOfPointChargeModalVisible
      ? `${POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT}px`
      : `${POINT_SECTION_HEIGHT}px`,
  }),
);
const StyledHeading = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
}));
const StyledBenefitOfPointCharge = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  marginTop: theme.spacing(1.5),
}));
const StyledTitle = styled(Typography)({
  fontWeight: 'bold',
});

type Props = {
  points: number | undefined;
  benefitOfPointChargeModalVisible: boolean;
};

const Presenter: React.VFC<Props> = ({
  points,
  benefitOfPointChargeModalVisible,
}) => (
  <StyledContainer
    isBenefitOfPointChargeModalVisible={benefitOfPointChargeModalVisible}
  >
    <StyledHeading container alignItems="center">
      <StyledTitle>所持ポイント</StyledTitle>
      <Points points={points} fontSize={POINT_FONT_SIZE.LG} />
    </StyledHeading>
    {benefitOfPointChargeModalVisible && (
      <StyledBenefitOfPointCharge container>
        <BenefitOfPointChargeModalWithButton />
      </StyledBenefitOfPointCharge>
    )}
  </StyledContainer>
);

export { Presenter };
