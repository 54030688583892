import React from 'react';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT } from '/@/components/shared/headingWithPoints/Presenter';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';
import { HeadingWithPoints } from '/@/components/shared/headingWithPoints/Index';
import { AvailablePointCharges } from './availablePointCharges/Index';
import { PointLogList } from './pointLogList/Index';
import { TAB_NAME } from './Index';
import { SelectTab } from '/@/components/shared/selectTab/Index';

const StyledBaseBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));
const StyledBoxContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    borderRadius: theme.spacing(2),
  },
  backgroundColor: theme.palette.background.default,
  padding: 0,
}));
const StyledMainContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px - ${POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
}));

type Props = {
  headerTitle: string;
  activeTabStatus: TAB_NAME;
  setActiveTabStatus: (tag: TAB_NAME) => void;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  activeTabStatus,
  setActiveTabStatus,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <StyledBaseBox>
      <StyledBoxContainer maxWidth="sm">
        <HeadingWithPoints benefitOfPointChargeModalVisible={true} />
        <StyledMainContainer>
          <SelectTab
            selectedTab={activeTabStatus}
            handleChangeTab={(value: string) => setActiveTabStatus(value as TAB_NAME)}
            tabs={[
              { label: 'ポイント有効期限', value: 'availablePointLogs', dataCyName: 'selectAvailablePointLogsTab' },
              { label: 'ポイント履歴', value: 'logList', dataCyName: 'selectLogListTab' },
            ]}
          />
          {activeTabStatus === 'availablePointLogs' && (
            <AvailablePointCharges />
          )}
          {activeTabStatus === 'logList' && <PointLogList />}
        </StyledMainContainer>
      </StyledBoxContainer>
    </StyledBaseBox>
  </MainLayout>
);

export { Presenter };
