import React from 'react';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { POINT_SECTION_HEIGHT } from '/@/components/shared/headingWithPoints/Presenter';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';
import { HeadingWithPoints } from '/@/components/shared/headingWithPoints/Index';
import { PointItemContents } from '/@/components/shared/pointItemContents/Index';
import { CautionText } from '/@/components/shared/cautionText/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';

const StyledContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));
const StyledItemsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px - ${POINT_SECTION_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
}));
const StyledCautionTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

type Props = {
  headerTitle: string;
  isRestoredSearchConditionWithURLParams: boolean;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  isRestoredSearchConditionWithURLParams,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <StyledContainer>
      <HeadingWithPoints benefitOfPointChargeModalVisible={true} />
      <StyledItemsContainer>
        {isRestoredSearchConditionWithURLParams ? (
          <PointItemContents />
        ) : (
          <PartialLoader />
        )}
        <StyledCautionTextContainer>
          <CautionText />
        </StyledCautionTextContainer>
      </StyledItemsContainer>
    </StyledContainer>
  </MainLayout>
);

export { Presenter };
