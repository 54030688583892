import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { PointFeatureGroup } from '/@/api/graphql/internalApi/types';
import { useAppSelector } from '/@/store';

type Props = {
  pointFeatureGroup: PointFeatureGroup;
};

const Container: React.VFC<Props> = ({ pointFeatureGroup }) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter pointFeatureGroup={pointFeatureGroup} />
  ) : (
    <Presenter pointFeatureGroup={pointFeatureGroup} />
  );
};

export { Container as PointFeatureGroup };
