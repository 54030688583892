import React from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import {
  giftsAvailabilityTabActions,
  SelectedGiftsAvailabilityTabState,
} from '/@/store/ui/selectedGiftsAvailabilityTab';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const selectedGiftsAvailabilityTab = useAppSelector(
    (state) => state.ui.selectedGiftsAvailabilityTab,
  );

  const handleGiftsAvailabilityTab = (
    selectedGiftsAvailabilityTabState: SelectedGiftsAvailabilityTabState,
  ) => {
    dispatch(
      giftsAvailabilityTabActions.selectgiftsAvailabilityTab(
        selectedGiftsAvailabilityTabState,
      ),
    );
  };

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      handleGiftsAvailabilityTab={handleGiftsAvailabilityTab}
      selectedGiftsAvailabilityTab={selectedGiftsAvailabilityTab}
    />
  ) : (
    <Presenter
      handleGiftsAvailabilityTab={handleGiftsAvailabilityTab}
      selectedGiftsAvailabilityTab={selectedGiftsAvailabilityTab}
    />
  );
};

export { Container as GiftsAvailabilityTabs };
