import React from 'react';
import {
  Grid2 as Grid,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { GroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '/@/components/shared/typography/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import { MyGift } from '/@/components/shared/myGift/Index';

const StyledAvailableGiftsContainer = styled('div')({});
const StyledGroupedLabelContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));
const StyledMyGiftContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
const StyledAvailableMyGiftsNotFoundContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledAvailableMyGiftsNotFoundTextContainer = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  }),
);

type Props = {
  groupedAvailableMyGifts: GroupedAvailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getAvailableMyGiftsConnectionStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  groupedAvailableMyGifts,
  infiniteScrollTarget,
  getAvailableMyGiftsConnectionStatus,
}) => (
  <Grid data-cy="availableMyGifts">
    {Object.keys(groupedAvailableMyGifts).length ? (
      <StyledAvailableGiftsContainer>
        {Object.entries(groupedAvailableMyGifts).map(([date, myGifts]) => (
          <React.Fragment key={date}>
            <StyledGroupedLabelContainer>
              <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM} isBold={false}>
                {date}
              </BaseTypography>
            </StyledGroupedLabelContainer>
            {myGifts?.map(
              (myGift) =>
                myGift && (
                  <>
                    <Divider />
                    <StyledMyGiftContainer key={myGift.urlCode}>
                      <MyGift myGift={myGift} />
                    </StyledMyGiftContainer>
                  </>
                ),
            )}
          </React.Fragment>
        ))}
      </StyledAvailableGiftsContainer>
    ) : (
      getAvailableMyGiftsConnectionStatus !== STATUS.IDLE &&
      getAvailableMyGiftsConnectionStatus !== STATUS.LOADING && (
        <StyledAvailableMyGiftsNotFoundContainer
          container
          justifyContent="center"
          direction="column"
        >
          <StyledAvailableMyGiftsNotFoundTextContainer>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD} isBold>
              現在ご利用可能なギフトはありません。
            </BaseTypography>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD} isBold>
              以下のページからギフトをもらえます！
            </BaseTypography>
          </StyledAvailableMyGiftsNotFoundTextContainer>
          <Divider />
          <BaseButton
            component={Link}
            to={PATHS.PROMOTIONS}
            data-cy="promotionsPageButton"
          >
            もらえるギフトを確認する
          </BaseButton>
        </StyledAvailableMyGiftsNotFoundContainer>
      )
    )}
    {getAvailableMyGiftsConnectionStatus === STATUS.LOADING && (
      <PartialLoader />
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
