import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { getPointWalletThunk } from '/@/store/api/queries/getPointWallet';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const myPoint = useAppSelector((state) => state.app.pointWallet?.point);
  const getPointWalletStatus = useAppSelector(
    (state) => state.api.getPointWallet.status,
  );
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate(PATHS.HOME);
  };

  useEffect(() => {
    // MEMO: subscriptionでpointWalletの取得を行うとtimeoutする問題ため
    // 暫定対応としてポイント移行完了画面描画時に再取得する
    dispatch(getPointWalletThunk());
  }, [dispatch]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      myPoint={myPoint}
      navigateToHome={navigateToHome}
      getPointWalletStatus={getPointWalletStatus}
    />
  ) : (
    <Presenter
      myPoint={myPoint}
      navigateToHome={navigateToHome}
      getPointWalletStatus={getPointWalletStatus}
    />
  );
};

export { Container as CompletionPage };
