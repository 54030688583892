import React from 'react';
import { Typography, Modal, Box, Button, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { MyGiftsDiscardConfirmationModalOpenState } from '/@/store/ui/myGiftsDiscardConfirmationModalOpen';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { SelectedMyGiftsState } from '/@/store/app/selectedMyGifts';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(),
  padding: theme.spacing(3),
  outline: 'none',
}));
const StyledConfirmation = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(),
}));
const StyledDiscardingList = styled('ul')(({ theme }) => ({
  padding: theme.spacing(2),
  margin: 0,
}));
const StyledDiscardingMyGift = styled(Typography)({
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
});
const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  display: 'inline-block',
  textAlign: 'center',
}));
const StyledConfirmButton = styled(LoadingButton)(({ theme }) => ({
  padding: theme.spacing(1.875),
  marginTop: theme.spacing(3),
  fontWeight: 'bold',
  lineHeight: '1',
  borderRadius: theme.spacing(2.75),
  boxShadow: 'none',
  width: '100%',
}));
const StyledCancelButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  fontWeight: 'bold',
  borderRadius: theme.spacing(2.75),
  padding: theme.spacing(1.625),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '1',
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: 'none',
}));

type Props = {
  myGiftsDiscardConfirmationModalOpen: MyGiftsDiscardConfirmationModalOpenState;
  handleMyGiftsDiscardConfirmationModalOpen: () => void;
  typicalSelectedMyGifts: SelectedMyGiftsState;
  otherSelectedMyGifts: SelectedMyGiftsState;
  handleDiscardMyGifts: () => void;
  isDiscardMyGiftsLoading: boolean;
};

const Presenter: React.VFC<Props> = ({
  myGiftsDiscardConfirmationModalOpen,
  handleMyGiftsDiscardConfirmationModalOpen,
  typicalSelectedMyGifts,
  otherSelectedMyGifts,
  handleDiscardMyGifts,
  isDiscardMyGiftsLoading,
}) => (
  <Modal
    data-cy="myGiftsDiscardConfirmationModal"
    open={myGiftsDiscardConfirmationModalOpen}
    onClose={handleMyGiftsDiscardConfirmationModalOpen}
  >
    <StyledBox>
      <StyledConfirmation variant="body2">
        「利用済み」にするとマイギフトへは戻せなくなります。以下のギフトを「利用済み」にしますか？
      </StyledConfirmation>
      <StyledDiscardingList>
        {typicalSelectedMyGifts.map((myGift) => (
          <li key={myGift.urlCode}>
            <StyledDiscardingMyGift variant="body2" key={myGift.urlCode}>
              {myGift.contentName}
            </StyledDiscardingMyGift>
          </li>
        ))}
        {otherSelectedMyGifts.length ? (
          <li>
            <StyledDiscardingMyGift variant="body2">
              その他 {otherSelectedMyGifts.length}件
            </StyledDiscardingMyGift>
          </li>
        ) : null}
      </StyledDiscardingList>
      <StyledDescription variant="caption">
        「利用済み」にしたギフトはマイギフトの「利用済み・期限切れ」タブで確認できます。
      </StyledDescription>
      <Grid container justifyContent="center">
        <StyledConfirmButton
          onClick={() => handleDiscardMyGifts()}
          disableElevation
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          disabled={false}
          loading={isDiscardMyGiftsLoading}
          data-cy="submitButton"
          data-gtm-click="giftsPage-editPage-discardConfirmationModal-submitButton"
        >
          利用済みにする
        </StyledConfirmButton>
        <StyledCancelButton
          onClick={() => handleMyGiftsDiscardConfirmationModalOpen()}
          variant="outlined"
          disableElevation
          data-cy="cancelButton"
          data-gtm-click="giftsPage-editPage-discardConfirmationModal-cancelButton"
        >
          キャンセル
        </StyledCancelButton>
      </Grid>
    </StyledBox>
  </Modal>
);

export { Presenter };
