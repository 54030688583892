import React, { useState, useEffect } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppDispatch, useAppSelector } from '/@/store/hooks';
import { getPointWalletThunk } from '/@/store/api/queries/getPointWallet';

export type TAB_NAME = 'availablePointLogs' | 'logList';

const Container: React.VFC<Record<string, never>> = () => {
  const headerTitle = '所持ポイント情報';

  const dispatch = useAppDispatch();

  const [activeTabStatus, setActiveTabStatus] =
    useState<TAB_NAME>('availablePointLogs');

  useEffect(() => {
    dispatch(getPointWalletThunk()).catch(() => {});
  }, [dispatch]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      headerTitle={headerTitle}
      activeTabStatus={activeTabStatus}
      setActiveTabStatus={setActiveTabStatus}
    />
  ) : (
    <Presenter
      headerTitle={headerTitle}
      activeTabStatus={activeTabStatus}
      setActiveTabStatus={setActiveTabStatus}
    />
  );
};

export { Container as PointPointLogsPage };
