import { types } from './types';
import { ApiError } from '/@/api/graphql/client';
import type { ReadAnnouncementMutation } from '/@/api/graphql/internalApi/types';

export const readAnnouncementStart = () =>
  ({
    type: types.READ_ANNOUNCEMENT_START,
  }) as const;

export const readAnnouncementSuccess = (
  user: NonNullable<ReadAnnouncementMutation['readUserAnnouncement']['user']>,
) =>
  ({
    type: types.READ_ANNOUNCEMENT_SUCCESS,
    user,
  }) as const;

export const readAnnouncementFailure = (error: ApiError) =>
  ({
    type: types.READ_ANNOUNCEMENT_FAILURE,
    error,
  }) as const;
