import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { LABEL_SIZE, LabelSizeTypes } from '../baseLabel/Presenter';
import { useAppSelector } from '/@/store';

type Props = {
  labelSize?: LabelSizeTypes;
};

const Container: React.FC<Props> = ({ labelSize = LABEL_SIZE.MEDIUM }) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter labelSize={labelSize} />
  ) : (
    <Presenter labelSize={labelSize} />
  );
};

export { Container as LimitedLabel };
