import React from 'react';
import { Typography, Grid2 as Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { Modal } from '/@/components/shared/modal/Index';
import { POINT_FONT_SIZE, Points } from '../../points/Index';

const StyledContents = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));
const StyledMessageContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 'bold',
  fontSize: '1rem',
  lineHeight: '1.4rem',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
}));
const StyledPointSection = styled('div')(({ theme }) => ({
  '& > :not(:last-child)': {
    marginBottom: theme.spacing(1.5),
  },
  '& > :last-child': {
    marginBottom: theme.spacing(3),
  },
}));
const StyledPointRow = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const StyledPointTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 'bold',
  color: theme.palette.grey[700],
}));
const StyledExchangeSubmitButton = styled(LoadingButton)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.875),
  fontWeight: 'bold',
  lineHeight: '1',
  borderRadius: theme.spacing(2.75),
  boxShadow: 'none',
}));
const StyledCancelButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  borderRadius: theme.spacing(2.75),
  padding: theme.spacing(1.625),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '1',
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: 'none',
}));

type Props = {
  cancelExchange: () => void;
  handleSubmit: () => void;
  myPoints: number | undefined;
  pointAmount: number | undefined;
  createPointExchangeStatus: string;
};

const Presenter: React.VFC<Props> = ({
  cancelExchange,
  handleSubmit,
  myPoints,
  pointAmount,
  createPointExchangeStatus,
}) => (
  <Modal handleClose={cancelExchange}>
    <StyledContents container direction="column">
      <StyledMessageContainer>
        <Typography>
          ポイントを使用してギフトを交換します。
          <br />
          よろしいですか？
        </Typography>
      </StyledMessageContainer>
      <StyledPointSection>
        <StyledPointRow container alignItems="center">
          <StyledPointTitle>使用ポイント</StyledPointTitle>
          <Points points={pointAmount} fontSize={POINT_FONT_SIZE.LG} isMinus={true} />
        </StyledPointRow>
        <StyledPointRow container alignItems="center">
          <StyledPointTitle>使用後残りポイント</StyledPointTitle>
          <Points
            points={
              pointAmount !== undefined &&
                myPoints !== undefined &&
                myPoints - pointAmount >= 0
                ? myPoints - pointAmount
                : null
            }
            fontSize={POINT_FONT_SIZE.LG}
          />
        </StyledPointRow>
      </StyledPointSection>
      <StyledExchangeSubmitButton
        variant="contained"
        color="primary"
        loading={createPointExchangeStatus === 'loading'}
        onClick={handleSubmit}
        disabled={
          pointAmount === undefined ||
          myPoints === undefined ||
          pointAmount > myPoints ||
          createPointExchangeStatus === 'loading'
        }
        data-gtm-click="shared-modal-itemExchangeModal-submitButton"
      >
        交換する
      </StyledExchangeSubmitButton>
      <StyledCancelButton
        variant="contained"
        onClick={cancelExchange}
        data-gtm-click="shared-modal-itemExchangeModal-cancelButton"
      >
        キャンセル
      </StyledCancelButton>
    </StyledContents>
  </Modal>
);

export { Presenter };
