import React, { useMemo, useState } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import expiredAtLabel from '/@/utils/expiredAtLabel';
import { useAppSelector } from '/@/store';

const Container: React.VFC<Record<string, never>> = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const myGift = useAppSelector((state) => state.app.myGift);

  const label = useMemo(() => {
    return myGift !== null && myGift.giftType
      ? expiredAtLabel(myGift.giftType)
      : '';
  }, [myGift]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return myGift ?
    isRenewalDesign ? (
      <NeoPresenter
        isOpenModal={isOpenModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        myGift={myGift}
        expiredAtLabel={label}
      />
    ) : (
      <Presenter
        isOpenModal={isOpenModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        myGift={myGift}
        expiredAtLabel={label}
      />
    ) : null;
};

export { Container as ChargeableGift };
