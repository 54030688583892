import {
  AppBar,
  Divider,
  IconButton,
  IconButtonProps,
  Slide,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useRef } from 'react';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import ArrowLeftIcon from '/@/assets/shared/icon/neo-arrow-left-icon.svg';
import { ItemDetailBase, exchangeItemType } from '/@/store/app/shared/types';
import { ItemExchangeModal } from '/@/components/shared/modal/ItemExchangeModal/Index';
import { getHtmlString } from '/@/utils/getHtmlString';
import { FormFieldWithValidation } from '/@/components/shared/FormFieldWithValidation/Index';
import { POINT_FONT_SIZE, Points } from '/@/components/shared/points/Index';
import '/@/assets/font/font.css';
import { DiscountPercentageLabel } from '/@/components/shared/label/discountPercentageLabel';
import { AlertMessage } from '/@/components/shared/alertMessage/Index';
import { LimitedLabel } from '/@/components/shared/label/limitedLabel/Index';
import { BaseButton } from '../../button/baseButton/Index';
import { neoTheme } from '/@/utils/theme';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';

const StyledModalAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: 1300,
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  alignItems: 'center',
  overflowY: 'auto',
}));
const StyledModalContent = styled('div')(({ theme }) => ({
  textAlign: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  paddingTop: `calc(${HEADER_HEIGHT}px + ${theme.spacing(0.5)})`,
  paddingBottom: theme.spacing(10),
}));
const StyledHeader = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  textAlign: 'center',
  height: `${HEADER_HEIGHT}px`,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
}));
const StyledAlertMessageContainer = styled('div')(({ theme }) => ({
  marginLeft: `-${theme.spacing(2)}`,
  marginRight: `-${theme.spacing(2)}`,
}));
const StyledArrowBackIcon = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    padding: 0,
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(1.5),
  }),
);
const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  fontWeight: 'bold',
  width: '100%',
}));
const StyledLogo = styled('img')({
  width: '100%',
  maxWidth: '480px',
});
const StyledOverview = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'left',
}));
const StyledBrandName = styled('p')({
  margin: 0,
  fontSize: '0.75rem',
  lineHeight: '1.5',
  color: neoTheme.palette.grey[700],
});
const StyledItemName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '1rem',
  lineHeight: '1.75',
  fontWeight: 'bold',
}));
const StyledEndAt = styled('p')(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: '0.75rem',
  color: theme.palette.grey.A700,
}));
const StyledLabels = styled('div')({
  display: 'flex',
  '& > * + *': {
    marginLeft: '0.25rem',
  },
});
const StyledPointText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.875rem',
  lineHeight: '1.6',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledVariablePointContainer = styled('div')(({ theme }) => ({
  color: neoTheme.palette.secondary.dark,
  fontWeight: 'normal',
  marginBottom: theme.spacing(1),
}));
type StyledVariablePointInputProps = {
  hasValidationError: boolean;
};
const StyledVariablePointInput = styled('input')<StyledVariablePointInputProps>(
  ({ hasValidationError, theme }) => ({
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1.25, 2),
    height: '48px',
    fontSize: '1rem',
    lineHeight: '1.75',
    textAlign: 'left',
    border: '1px solid ' + theme.palette.grey.A200,
    borderRadius: theme.spacing(0.75),
    ...(hasValidationError && {
      border: 'none',
      outline: '2px solid #FF0000',
    }),
  }),
);
const StyledVariablePointNote = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  color: neoTheme.palette.secondary.dark,
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(3),
}));
const StyledDetailTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 'bold',
  textAlign: 'left',
  color: neoTheme.palette.secondary.dark,
});
const StyledDetailText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  marginTop: theme.spacing(1),
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  color: neoTheme.palette.secondary.dark,
}));
const StyledCautionTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 'bold',
  textAlign: 'left',
  color: neoTheme.palette.secondary.dark,
});
const StyledCautionText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  marginTop: theme.spacing(1),
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  color: neoTheme.palette.secondary.dark,
}));
const StyledPointCautionTitle = styled(Typography)({
  fontSize: '1rem',
  fontWeight: 'bold',
  textAlign: 'left',
  color: neoTheme.palette.secondary.dark,
});
const StyledPointCautionText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  marginTop: theme.spacing(1),
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  color: neoTheme.palette.secondary.dark,
}));
const StyledExchangeButtonContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  width: `calc(100% - ${theme.spacing(6)})`,
  maxWidth: '480px',
}));

type Props = {
  onClose: () => void;
  itemDetail: ItemDetailBase | null;
  isOpenItemExchangeModal: boolean;
  handleSwitchItemExchangeModal: () => void;
  exchangeItemType: exchangeItemType;
  variablePoint: number;
  handleChangeVariablePoint: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabledChargeButton: boolean;
  validationMessage: string | null;
};
const Presenter: React.VFC<Props> = ({
  onClose,
  itemDetail,
  isOpenItemExchangeModal,
  handleSwitchItemExchangeModal,
  exchangeItemType,
  variablePoint,
  handleChangeVariablePoint,
  isDisabledChargeButton,
  validationMessage,
}) => {
  if (!itemDetail) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const modalRef = useRef<HTMLDivElement>(null);

  // MEMO: 交換時エラーのalertMessage表示時用
  const scrollToTop = () => {
    if (modalRef.current) modalRef.current.scrollTop = 0;
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <StyledModalAppBar
        ref={modalRef}
        variant="outlined"
        color="default"
        data-cy="header"
      >
        <StyledModalContent>
          <StyledHeader>
            <StyledArrowBackIcon
              color="secondary"
              onClick={onClose}
              component="button"
              data-gtm-click="shared-pointItemContents-itemDetailModal-closeButton"
            >
              <img src={ArrowLeftIcon} alt='戻るボタン' />
            </StyledArrowBackIcon>
            <StyledTitle>ギフト詳細</StyledTitle>
          </StyledHeader>
          <StyledAlertMessageContainer>
            <AlertMessage />
          </StyledAlertMessageContainer>
          <StyledLogo
            src={itemDetail.contentImageUrl}
            alt={itemDetail.contentName}
          />
          <StyledOverview>
            <StyledBrandName>{itemDetail.brand.name}</StyledBrandName>
            <StyledItemName>{itemDetail.contentName}</StyledItemName>
            {itemDetail.currentItemPointPhase && (
              <>
                <StyledEndAt>
                  {'有効期限 ' + itemDetail.readableExpiration}
                </StyledEndAt>
                <FormFieldWithValidation validationMessage={validationMessage}>
                  <StyledPointText>
                    {exchangeItemType === 'fixedAmount' ? (
                      <Points
                        points={itemDetail.currentItemPointPhase.point}
                        fontSize={POINT_FONT_SIZE.LG}
                      />
                    ) : (
                      <StyledVariablePointContainer>
                        <StyledVariablePointInput
                          type="text"
                          value={variablePoint.toLocaleString('ja-JP')}
                          onChange={handleChangeVariablePoint}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          hasValidationError={!!validationMessage}
                          min={1}
                          max={999999}
                        />
                        {'ポイント'}
                      </StyledVariablePointContainer>
                    )}
                  </StyledPointText>
                </FormFieldWithValidation>
                {exchangeItemType === 'variable' && (
                  <StyledVariablePointNote>
                    <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM} isBold={false}>
                      ※還元が１ポイント未満の場合付与されません
                    </BaseTypography>
                  </StyledVariablePointNote>
                )}
                <StyledLabels>
                  {itemDetail.currentPointBackCampaign?.limited && (
                    <LimitedLabel />
                  )}
                  {itemDetail.currentPointBackCampaign && (
                    <DiscountPercentageLabel
                      discountPercentage={
                        itemDetail.currentPointBackCampaign.discountPercentage
                      }
                    />
                  )}
                </StyledLabels>
              </>
            )}
          </StyledOverview>

          <StyledDivider />

          <StyledDetailTitle>ギフト詳細</StyledDetailTitle>
          <StyledDetailText
            dangerouslySetInnerHTML={{
              __html: getHtmlString(itemDetail.description),
            }}
          />

          <StyledDivider />

          <StyledCautionTitle>ご利用上の注意事項</StyledCautionTitle>
          <StyledCautionText
            dangerouslySetInnerHTML={{
              __html: getHtmlString(itemDetail.caution),
            }}
          />

          <StyledDivider />

          <StyledPointCautionTitle>
            ポイントに関する注意事項
          </StyledPointCautionTitle>
          <StyledPointCautionText
            dangerouslySetInnerHTML={{
              __html: getHtmlString(itemDetail.brand.pointCaution),
            }}
          />
          <StyledExchangeButtonContainer>
            <BaseButton
              disabled={isDisabledChargeButton}
              onClick={handleSwitchItemExchangeModal}
              data-cy="exchangeButton"
              data-gtm-click="shared-pointItemContents-itemDetailModal-exchangeButton"
            >
              交換にすすむ
            </BaseButton>
          </StyledExchangeButtonContainer>
        </StyledModalContent>
        {isOpenItemExchangeModal && (
          <ItemExchangeModal
            handleClose={handleSwitchItemExchangeModal}
            scrollToTop={scrollToTop}
          />
        )}
      </StyledModalAppBar>
    </Slide>
  );
};

export { Presenter };
