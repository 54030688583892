import React, { useEffect, useMemo, useState } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getPointWalletThunk } from '/@/store/api/queries/getPointWallet';
import { useLocation, useParams } from 'react-router-dom';
import { setItemSearchCondition } from '/@/store/app/itemSearchCondition/actions';
import { getBrandThunk } from '/@/store/api/queries/getBrand';
import { brandActions } from '/@/store/app/brand';
import { ItemSearchConditionBase } from '/@/store/app/shared/types';

const Container: React.VFC<Record<string, never>> = () => {
  const { uid } = useParams();
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const minPoint = searchParam.get('minPoint') ?? undefined;
  const maxPoint = searchParam.get('maxPoint') ?? undefined;
  const point = useAppSelector((state) => state.app.pointWallet?.point);
  const brand = useAppSelector((state) => state.app.brand);
  const getBrandStatus = useAppSelector((state) => state.api.getBrand.status);
  const [
    isRestoredSearchConditionWithURLParams,
    setIsRestoredSearchConditionWithURLParams,
  ] = useState<boolean>(false);

  const headerTitle = useMemo(() => {
    return getBrandStatus === 'loading' ? '-' : (brand?.name ?? '商品検索結果');
  }, [getBrandStatus, brand]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (point === undefined) dispatch(getPointWalletThunk());

    return () => {
      // アンマウント時にbrandを初期化
      if (brand) dispatch(brandActions.initialiseBrand());
    };
  }, [dispatch, point, brand]);

  useEffect(() => {
    if (uid && uid !== brand?.uid) {
      dispatch(getBrandThunk({ uid }));
    } else if (uid === undefined) {
      dispatch(brandActions.initialiseBrand());
    }
  }, [dispatch, uid, brand?.uid]);

  useEffect(() => {
    // URLパラメータ復元用
    const newCondition: ItemSearchConditionBase = {
      ...(uid && { brandUid: uid }),
      ...(minPoint && { minPoint: parseInt(minPoint) }),
      ...(maxPoint && { maxPoint: parseInt(maxPoint) }),
    };

    dispatch(setItemSearchCondition(newCondition));
    setIsRestoredSearchConditionWithURLParams(true);
  }, [dispatch, uid, minPoint, maxPoint]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      headerTitle={headerTitle}
      isRestoredSearchConditionWithURLParams={
        isRestoredSearchConditionWithURLParams
      }
    />
  ) : (
    <Presenter
      headerTitle={headerTitle}
      isRestoredSearchConditionWithURLParams={
        isRestoredSearchConditionWithURLParams
      }
    />
  );
};

export { Container as ItemsPage };
