import React from 'react';
import { BaseLabel } from '../baseLabel';
import { LabelSizeTypes } from '../baseLabel/Presenter';
import { neoTheme } from '/@/utils/theme';

type Props = {
  labelSize: LabelSizeTypes;
};

const Presenter: React.VFC<Props> = ({ labelSize }) => (
  <BaseLabel
    labelText={'限定'}
    labelSize={labelSize}
    color={'#1DABD6'}
    backgroundColor={neoTheme.palette.background.contrast}
  />
);

export { Presenter };
