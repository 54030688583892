import React from 'react';
import { Card, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { BrandBase } from '/@/store/app/shared/types';
import { DiscountPercentageLabel } from '/@/components/shared/label/discountPercentageLabel';
import { LABEL_SIZE } from '/@/components/shared/label/baseLabel/Presenter';
import { LimitedLabel } from '/@/components/shared/label/limitedLabel/Index';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '../../typography/Index';
import { neoTheme } from '/@/utils/theme';

const StyledBrandCardContainer = styled('div')({
  width: '100%',
});
type StyledCardProps = {
  isLastItem?: boolean;
};
const StyledRecommendedBrandContainer = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  width: '100%',
  border: 'none',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
const StyledCard = styled(Card)<StyledCardProps>(({ isLastItem }) => ({
  marginBottom: 'auto',
  marginRight: isLastItem ? 'auto' : 0,
  width: 'calc((100% - 2rem) / 3)',
  minWidth: '100px',
  textAlign: 'left',
  border: 'none',
}));
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  border: '2px solid ' + neoTheme.palette.secondary.light,
}));
const StyledRecommendedBrandLogo = styled('img')(({ theme }) => ({
  width: '60px',
  height: '60px',
  objectFit: 'contain',
  borderRadius: theme.spacing(1),
  border: '2px solid ' + neoTheme.palette.secondary.light,
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  width: '-webkit-fill-available',
}));
const StyledBrandName = styled('div')({
  color: neoTheme.palette.secondary.dark,
});
const StyledDescription = styled('div')({
  fontSize: '0.75rem',
  lineHeight: '1.5',
  color: neoTheme.palette.secondary.dark,
});
const StyledLabels = styled('div')({
  '& > * + *': {
    marginLeft: '0.25rem',
  },
});

type Props = {
  index: number;
  brand: BrandBase;
  isRecommended: boolean;
  isLastItem?: boolean;
  navigateItemPage: () => void;
};

const Presenter: React.VFC<Props> = ({
  index,
  brand,
  isRecommended,
  isLastItem,
  navigateItemPage,
}) => {
  return isRecommended ? (
    <StyledBrandCardContainer>
      <Divider />
      <StyledRecommendedBrandContainer variant='outlined' onClick={navigateItemPage}>
        <StyledRecommendedBrandLogo src={brand.iconUrl} alt={brand.name} />
        <StyledRightContent>
          <StyledBrandName>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.MD} isBold>{brand.name}</BaseTypography>
          </StyledBrandName>
          <StyledDescription>
            <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM} isBold={false}>{brand.currentPointBackCampaign?.description}</BaseTypography>
          </StyledDescription>
          <StyledLabels>
            {brand.currentPointBackCampaign && (
              <DiscountPercentageLabel
                discountPercentage={
                  brand.currentPointBackCampaign.discountPercentage
                }
                labelSize={LABEL_SIZE.MEDIUM}
              />
            )}
            {brand.currentPointBackCampaign?.limited && (
              <LimitedLabel labelSize={LABEL_SIZE.MEDIUM} />
            )}
          </StyledLabels>
        </StyledRightContent>
      </StyledRecommendedBrandContainer>
    </StyledBrandCardContainer>
  ) : (
    <StyledCard
      key={index}
      variant="outlined"
      onClick={navigateItemPage}
      isLastItem={isLastItem}
      data-cy="brandCard"
      data-gtm-click="shared-brandContents-brandCard-card"
    >
      <StyledLogoContainer>
        <StyledLogo src={brand.iconUrl} alt={brand.name} />
      </StyledLogoContainer>
      <>
        <StyledBrandName>
          <BaseTypography fontType={FONT_TYPE.BODY} fontSize={FONT_SIZE.SM} isBold={false}>{brand.name}</BaseTypography>
        </StyledBrandName>
        <StyledLabels>
          {brand.currentPointBackCampaign?.discountPercentage && (
            <DiscountPercentageLabel
              discountPercentage={
                brand.currentPointBackCampaign?.discountPercentage
              }
              isEmphased={isRecommended}
              labelSize={LABEL_SIZE.MEDIUM}
            />
          )}
          {brand.currentPointBackCampaign?.limited && (
            <LimitedLabel labelSize={LABEL_SIZE.MEDIUM} />
          )}
        </StyledLabels>
      </>
      {isRecommended && brand.currentPointBackCampaign?.description && (
        <StyledDescription>
          {brand.currentPointBackCampaign.description}
        </StyledDescription>
      )}
    </StyledCard>
  )
};

export { Presenter };
