import React from 'react';
import { Grid2 as Grid, Divider } from '@mui/material';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { PointLog } from '/@/components/shared/pointLog/Index';
import { GroupedAvailablePointCharges } from '/@/store/page/pointLogsPage/availablePointChargesPage/selectors';
import { formatToEndDateJa } from '/@/utils/formatDate';

type Props = {
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  groupedAvailablePointChargesByMonth: GroupedAvailablePointCharges;
  getAvailablePointChargesStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  infiniteScrollTarget,
  groupedAvailablePointChargesByMonth,
  getAvailablePointChargesStatus,
}) => (
  <Grid data-cy="availablePointCharges">
    {getAvailablePointChargesStatus == STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <>
        {Object.entries(groupedAvailablePointChargesByMonth).map(
          ([month, availablePointCharges]) => (
            <React.Fragment key={month}>
              <Grid>
                {availablePointCharges.map((availablePointCharge, index) => {
                  const title = `${formatToEndDateJa(
                    availablePointCharge.withdrawableUntilAt,
                  )}まで有効`;
                  return (
                    <React.Fragment key={index}>
                      <PointLog
                        title={title}
                        point={availablePointCharge.remainingAmount}
                      />
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </React.Fragment>
          ),
        )}
      </>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
