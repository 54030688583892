import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 3),
}));
const StyledSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  lineHeight: '1',
  marginBottom: theme.spacing(0.5),
  textAlign: 'center',
}));
const StyledMainTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  lineHeight: '1.3',
  textAlign: 'center',
}));
const StyledMainText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.4',
  textAlign: 'left',
  marginTop: theme.spacing(3),
}));
const StyledListTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  fontWeight: 'bold',
  lineHeight: '1',
  color: theme.palette.secondary.dark,
  marginTop: theme.spacing(3),
}));
const StyledHighReturnBrandsImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  marginTop: theme.spacing(1.5),
}));
const StyledExchangeableBrandsImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  marginTop: theme.spacing(1.5),
}));

type Props = {
  children?: React.ReactNode;
  highReturnBrandsImageURL: string;
  exchangeableBrandsImageURL: string;
};
const Presenter: React.VFC<Props> = ({
  children,
  highReturnBrandsImageURL,
  exchangeableBrandsImageURL,
}) => (
  <StyledContainer data-cy="signInPageCampaignSection">
    <Grid container direction="column" alignContent="center">
      <Grid size="grow">
        <StyledSubTitle variant="h3">いまなら</StyledSubTitle>
        <StyledMainTitle variant="h1">最大10%のポイント還元</StyledMainTitle>
      </Grid>
      <Grid size="grow">
        <StyledMainText variant="body1">
          gift wallet
          でギフトを交換すると、その場で還元されるおトクなギフトも多数。
          <br />
          今なら最大10%還元となるギフトも！
        </StyledMainText>
      </Grid>
      {highReturnBrandsImageURL !== '' && (
        <Grid size="grow">
          <StyledListTitle variant="body1">高還元ブランド例</StyledListTitle>
          <StyledHighReturnBrandsImage
            src={highReturnBrandsImageURL}
            alt="highReturnBrandsImage"
          />
        </Grid>
      )}
      {exchangeableBrandsImageURL !== '' && (
        <Grid size="grow">
          <StyledListTitle variant="body1">
            その他交換可能なギフトも多数！
          </StyledListTitle>
          <StyledExchangeableBrandsImage
            src={exchangeableBrandsImageURL}
            alt="exchangeableBrandsImage"
          />
        </Grid>
      )}
      {children && <Grid size="grow">{children}</Grid>}
    </Grid>
  </StyledContainer>
);

export { Presenter };
