import React from 'react';
import { Typography, Grid2 as Grid, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { ExpiringMyGiftsState } from '/@/store/app/expiringMyGiftsConnection';
import { MYGIFT_DISPLAY_SIZE, MyGift } from '/@/components/shared/myGift/Index';
import { Card } from '/@/components/shared/card/Index';

const StyledContainer = styled(Container)({
  padding: 0 + ' !important',
});
const StyledCaption = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.grey[700],
}));
const StyledExpiringMyGiftsScrollContainer = styled(Grid)(
  ({ theme }) => ({
    margin: theme.spacing(0, -2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2),
    width: 'auto',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
);
const StyledMyGiftContainer = styled('div')({
  width: '100%',
});
const StyledMultiMyGiftsContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  maxWidth: '90%',
  '&:last-child': {
    marginRight: 0,
  },
}));
const StyledMyGift = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: theme.spacing(1),
}));

type Props = {
  expiredMyGifts: ExpiringMyGiftsState;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getExpiringMyGiftsConnectionStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  expiredMyGifts,
  infiniteScrollTarget,
  getExpiringMyGiftsConnectionStatus,
}) => (
  <>
    {expiredMyGifts?.length ? (
      <StyledContainer maxWidth="sm" data-cy="expiringMyGifts">
        <Card>
          <StyledCaption variant="body2">
            有効期限が近づいています
          </StyledCaption>
          <StyledExpiringMyGiftsScrollContainer container>
            {expiredMyGifts.length === 1 ? (
              expiredMyGifts[0] && (
                <StyledMyGiftContainer>
                  <StyledMyGift>
                    <MyGift myGift={expiredMyGifts[0]} myGiftDisplaySize={MYGIFT_DISPLAY_SIZE.SM} />
                  </StyledMyGift>
                </StyledMyGiftContainer>
              )) : (
              expiredMyGifts.map((myGift) => myGift && (
                <StyledMultiMyGiftsContainer key={myGift.urlCode}>
                  <StyledMyGift>
                    <MyGift myGift={myGift} myGiftDisplaySize={MYGIFT_DISPLAY_SIZE.SM} />
                  </StyledMyGift>
                </StyledMultiMyGiftsContainer>
              ))
            )}
          </StyledExpiringMyGiftsScrollContainer>
        </Card>
      </StyledContainer>
    ) : (
      <></>
    )}
    {getExpiringMyGiftsConnectionStatus === STATUS.LOADING && <PartialLoader />}
    <div ref={infiniteScrollTarget} />
  </>
);

export { Presenter };
