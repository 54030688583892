import React from 'react';
import { Typography, TypographyProps, Grid2 as Grid, Container, ContainerProps } from '@mui/material';
import { styled } from '@mui/system';
import {
  MyGiftUsageStatus,
  MY_GIFT_USAGE_STATUS,
} from '/@/utils/calcMyGiftUsageStatus';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { neoTheme } from '/@/utils/theme';
import { MYGIFT_DISPLAY_SIZE, MyGiftDisplaySize } from '../Index';
import ArrowRightIcon from '/@/assets/shared/icon/arrow-right-icon.svg';

type StyledContainerProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
} & ContainerProps;
const StyledContainer = styled(Container)<StyledContainerProps>(({ theme, myGiftDisplaySize }) => ({
  backgroundColor: neoTheme.palette.common.white,
  padding: myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? 0 + ' !important' : theme.spacing(2) + ' !important',
}));
const StyledContainerContent = styled(Grid)({
  minWidth: 0,
  width: '-webkit-fill-available',
});
type StyledMyGiftNameProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
} & TypographyProps;
const StyledMyGiftName = styled(Typography)<StyledMyGiftNameProps>(({ myGiftDisplaySize }) => ({
  color: neoTheme.palette.secondary.dark,
  fontWeight: 'bold',
  overflow: 'hidden',
  fontSize: myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '0.875rem' : '0.75rem',
  lineHeight: '1.5',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
}));
type StyledPointChargeableTextProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
} & TypographyProps;
const StyledPointChargeableText = styled(Typography)<StyledPointChargeableTextProps>(({ theme, myGiftDisplaySize }) => ({
  fontSize: myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '0.875rem' : '0.75rem',
  lineHeight: '1.5',
  fontWeight: 'bold',
  color: '#1DABD6',
  marginLeft: theme.spacing(1),
  display: 'inline-block',
}));
type StyledMyGiftImageProps = {
  myGiftDisplaySize: MyGiftDisplaySize;
} & React.ImgHTMLAttributes<HTMLImageElement>;
const StyledMyGiftImage = styled('img')<StyledMyGiftImageProps>(({ theme, myGiftDisplaySize }) => ({
  marginRight: theme.spacing(1.5),
  height: myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '64px' : '40px',
  width: myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD ? '64px' : '40px',
  borderRadius: theme.spacing(),
  border: `1px solid ${theme.palette.grey[300]}`,
  objectFit: 'contain',
}));

type StyledMyGiftExpiredAtProps = {
  isExpiring: boolean;
};
const StyledMyGiftExpiredAt = styled(Typography)<StyledMyGiftExpiredAtProps>(
  ({ isExpiring, theme }) => ({
    fontSize: '0.75rem',
    lineHeight: '1.5',
    marginTop: theme.spacing(0.5),
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    ...(isExpiring
      ? {
        color: theme.palette.warning.main,
      }
      : {
        color: theme.palette.secondary.main,
      }),
  }),
);
const StyledExpiredCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
    width: '0.875rem',
    color: theme.palette.warning.main,
  },
}));
const StyledDiscardedCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
    width: '0.875rem',
    color: theme.palette.primary.main,
  },
}));

type Props = {
  name: string;
  imageUrl: string;
  expirationText: string;
  usageStatus: MyGiftUsageStatus;
  pointMergeable: boolean;
  handleClick: () => void;
  myGiftDisplaySize: MyGiftDisplaySize;
  isHiddenMergeableText: boolean;
};

const Presenter: React.VFC<Props> = ({
  name,
  imageUrl,
  expirationText,
  usageStatus,
  pointMergeable,
  handleClick,
  myGiftDisplaySize,
  isHiddenMergeableText,
}) => (
  <StyledContainer
    onClick={handleClick}
    data-cy="myGiftSkelton"
    data-gtm-click="shared-myGift-myGiftSkelton-card"
    myGiftDisplaySize={myGiftDisplaySize}
  >
    <Grid container wrap="nowrap" direction="row" justifyContent="space-between">
      <StyledMyGiftImage src={imageUrl} alt={name} myGiftDisplaySize={myGiftDisplaySize} />
      <StyledContainerContent container justifyContent="center" direction="column">
        <StyledMyGiftName variant="subtitle1" myGiftDisplaySize={myGiftDisplaySize}>
          {name}
          {pointMergeable && !isHiddenMergeableText && (
            <StyledPointChargeableText myGiftDisplaySize={myGiftDisplaySize}>
              ポイントに移行可能
            </StyledPointChargeableText>
          )}
        </StyledMyGiftName>
        <Grid container alignItems="center">
          {usageStatus === MY_GIFT_USAGE_STATUS.DISCARDED ? (
            <StyledDiscardedCheckCircleIcon data-cy="discardedCheckCircleIcon" />
          ) : usageStatus === MY_GIFT_USAGE_STATUS.EXPIRED ? (
            <StyledExpiredCheckCircleIcon data-cy="expiredCheckCircleIcon" />
          ) : null}
          <StyledMyGiftExpiredAt
            variant="caption"
            isExpiring={usageStatus === MY_GIFT_USAGE_STATUS.EXPIRING}
            data-cy="expirationText"
          >
            {expirationText}
          </StyledMyGiftExpiredAt>
        </Grid>
      </StyledContainerContent>
      {myGiftDisplaySize === MYGIFT_DISPLAY_SIZE.MD && (
        <Grid container alignItems="center" justifyContent="center">
          <img
            src={ArrowRightIcon}
            alt="arrow-right"
            width="20"
            height="20"
          />
        </Grid>
      )}
    </Grid>
  </StyledContainer>
);

export { Presenter };
