import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';

const Container: React.VFC<{
  isLogoClickable: boolean;
}> = ({ isLogoClickable }) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter isLogoClickable={isLogoClickable} />
  ) : (
    <Presenter isLogoClickable={isLogoClickable} />
  );
};

export { Container as HeaderLogo };
