import React, { forwardRef, useMemo } from 'react';
import {
  AppBar,
  Dialog,
  List,
  ListItemText,
  Divider,
  Typography,
  Grid2 as Grid,
  Button,
  TextField,
  ListItemButton,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/system';
import ArrowLeftIcon from '/@/assets/shared/icon/neo-arrow-left-icon.svg';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import closeIcon from '/@/assets/shared/icon/neo-close-icon.svg';
import arrowRightIcon from '/@/assets/templates/header/searchHeader/arrow-right-icon.svg';
import sliderIcon from '/@/assets/templates/header/searchHeader/neo-slider-icon.svg';
import { BrandCondition } from './brandCondition/Index';
import { ConditionOpenedType } from '.';
import { neoTheme, theme } from '/@/utils/theme';
import { ItemSearchConditionState } from '/@/store/app/itemSearchCondition/reducers';
import { HeaderTitle } from '../headerComponents/headerTitle/Index';
import { BaseButton } from '/@/components/shared/button/baseButton/Index';
import { BaseTypography, FONT_SIZE, FONT_TYPE } from '/@/components/shared/typography/Index';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: 'none',
}));
const StyledTitleGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  height: `${HEADER_HEIGHT}px`,
  minHeight: `${HEADER_HEIGHT}px`,
}));
const StyledSearchMenuDrawer = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(6),
    // 右下と左下のborderraadiusを削除
    '& .MuiDialog-paper': {
      borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
    },
  },
  [theme.breakpoints.up('md')]: {
    // 画面中央に表示
    margin: 'auto',
    maxHeight: '765px',
    maxWidth: '680px',
    '& .MuiDialog-paper': {
      borderRadius: theme.spacing(1),
    },
  },
}));
const StyledSearchMenuHeader = styled('div')({
  height: '80px',
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
});
const StyledStyledSearchMenuTitleContainer = styled('div')(({ theme }) => ({
  height: '80px',
  paddingTop: theme.spacing(3.75),
  textAlign: 'center',
}));
const StyledBackButton = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));
const StyledClearButton = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  color: neoTheme.palette.secondary.dark,
  textDecoration: 'underline',
}));
const StyledCloseIcon = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(3),
  top: theme.spacing(4),
  height: '13px',
  width: '13px',
  transform: 'translateX(-50%)',
}));
const StyledConditionsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  paddingBottom: theme.spacing(10),
  overflowY: 'auto',
}));
const StyledListTitle = styled(BaseTypography)(({ theme }) => ({
  display: 'inline-block',
  color: neoTheme.palette.secondary.dark,
  marginBottom: theme.spacing(1),
}));
const StyledMenuListItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));
const StyledPointRangeInputs = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});
const StyledMenuListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
    color: neoTheme.palette.secondary.dark,
  },
});
const StyledPointInputField = styled(TextField)({
  width: '104px',
  '& input': {
    textAlign: 'left !important',
  },
});
const StyledButtonContainer = styled('div')(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(4)})`, // 両サイドのpadding分を引く
  display: 'flex',
  justifyContent: 'space-between',
  // 2個目以降のボタンにmargin-leftを設定
  '& button + button': {
    marginLeft: theme.spacing(1),
  },
  position: 'absolute',
  bottom: theme.spacing(5),
}));

type Props = {
  headerTitle: string;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
  isConditionOpened: ConditionOpenedType;
  handleOpenCondition: (type: ConditionOpenedType) => void;
  handleNavigateBack: () => void;
  selectedBrandName: string | null;
  conditions: ItemSearchConditionState;
  setConditions: (conditions: ItemSearchConditionState) => void;
  searchItems: () => void;
  handleClearBrandCondition: () => void;
  handleClearCondition: () => void;
  setMinPointCondition: (minPoint: number) => void;
  setMaxPointCondition: (maxPoint: number) => void;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  isMenuOpened,
  handleToggleMenu,
  isConditionOpened,
  handleOpenCondition,
  handleNavigateBack,
  selectedBrandName,
  conditions,
  setConditions,
  searchItems,
  handleClearBrandCondition,
  handleClearCondition,
  setMinPointCondition,
  setMaxPointCondition,
}) => {

  const Transition = useMemo(() => {
    return forwardRef(function Transition(
      props: TransitionProps & {
        children: React.ReactElement<unknown>;
      },
      ref: React.Ref<unknown>,
    ) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  }, []);
  return (
    <>
      <StyledAppBar
        position="fixed"
        variant="outlined"
        color="default"
        data-cy="header"
      >
        <StyledTitleGrid container alignItems="center">
          <HeaderTitle
            headerTitle={headerTitle}
            handleNavigateBack={handleNavigateBack}
          />
          <Button
            onClick={handleToggleMenu}
            style={{ minWidth: 'auto', padding: 0 }}
            data-gtm-click="templates-header-handleToggleMenuButton"
          >
            <img
              src={sliderIcon}
              alt="絞り込みメニュー"
              width="24"
              height="24"
              data-cy="sliderIcon"
            />
          </Button>
        </StyledTitleGrid>
      </StyledAppBar>

      <StyledSearchMenuDrawer
        fullScreen
        open={isMenuOpened}
        onClose={handleToggleMenu}
        TransitionComponent={window.innerWidth < theme.breakpoints.values.md ? Transition : undefined}
      >
        <StyledSearchMenuHeader>
          {isConditionOpened === "brand" ? (
            <>
              <StyledBackButton
                onClick={() => handleOpenCondition(null)}
                data-gtm-click="templates-header-handleCloseBrandConditionButton"
              >
                <img src={ArrowLeftIcon} alt="戻るボタン" />
              </StyledBackButton>
              <StyledStyledSearchMenuTitleContainer>
                <BaseTypography fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.MD}>
                  ブランド
                </BaseTypography>
              </StyledStyledSearchMenuTitleContainer>
              <StyledClearButton
                onClick={handleClearBrandCondition}
                data-gtm-click="templates-header-handleClearBrandConditionButton"
              >
                クリア
              </StyledClearButton>
            </>
          ) : (
            <StyledCloseIcon
              src={closeIcon}
              alt="閉じるボタン"
              onClick={handleToggleMenu}
              data-gtm-click="templates-header-handleCloseMenuButton"
            />
          )}
        </StyledSearchMenuHeader>
        {isConditionOpened === 'brand' ? (
          <BrandCondition
            handleClose={() => handleOpenCondition(null)}
            conditions={conditions}
            setConditions={setConditions}
          />
        ) : (
          <StyledConditionsContainer>
            <List
              data-cy="menuList"
              component="nav"
              aria-label="menu-list"
              style={{ paddingTop: 0 }}
            >
              <StyledListTitle fontType={FONT_TYPE.HEADLINE} fontSize={FONT_SIZE.MD}>
                商品検索
              </StyledListTitle>
              <StyledMenuListItem
                onClick={() => handleOpenCondition('brand')}
                data-gtm-click="templates-header-handleOpenBrandConditionButton"
              >
                <StyledMenuListItemText primary="ブランド" />
                <div
                  style={{
                    color: selectedBrandName ? 'black' : theme.palette.grey.A400,
                    fontSize: '0.875rem',
                  }}
                >
                  {selectedBrandName ?? '指定なし'}
                  <img
                    src={arrowRightIcon}
                    alt="条件選択ボタン"
                    width="24"
                    height="12"
                    data-cy="arrowRightIcon"
                  />
                </div>
              </StyledMenuListItem>
              <Divider component="li" />
              <StyledMenuListItem onClick={() => handleOpenCondition('point')}>
                <StyledMenuListItemText primary="ポイント" />
                <StyledPointRangeInputs>
                  <StyledPointInputField
                    type="number"
                    placeholder="100"
                    variant="outlined"
                    value={
                      conditions?.minPoint && conditions.minPoint > 0
                        ? conditions.minPoint
                        : ''
                    }
                    onChange={(e) => {
                      if (typeof Number(e.target.value) == 'number')
                        setMinPointCondition(Number(e.target.value));
                    }}
                    slotProps={{
                      input: {
                        inputProps: {
                          min: 0,
                          max: 999999,
                          style: { textAlign: 'right' },
                        },
                      },
                    }}
                  />
                  <Typography
                    style={{ display: 'inline-block', margin: 'auto 0.5rem' }}
                  >
                    {'〜'}
                  </Typography>
                  <StyledPointInputField
                    type="number"
                    placeholder="10000"
                    variant="outlined"
                    value={
                      conditions?.maxPoint && conditions.maxPoint > 0
                        ? conditions.maxPoint
                        : ''
                    }
                    onChange={(e) => {
                      if (typeof Number(e.target.value) == 'number')
                        setMaxPointCondition(Number(e.target.value));
                    }}
                    slotProps={{
                      input: {
                        inputProps: {
                          min: 0,
                          max: 999999,
                          style: { textAlign: 'right' },
                        },
                      },
                    }}
                  />
                </StyledPointRangeInputs>
              </StyledMenuListItem>
            </List>
            <StyledButtonContainer>
              <BaseButton
                color="secondary"
                isTransparent
                onClick={handleClearCondition}
                data-gtm-click="templates-header-handleClearConditionButton"
              >
                リセット
              </BaseButton>
              <BaseButton
                color="primary"
                onClick={searchItems}
                disabled={
                  !!(
                    conditions?.minPoint &&
                    conditions?.maxPoint &&
                    conditions?.minPoint > conditions?.maxPoint
                  )
                }
                data-gtm-click="templates-header-searchButton"
              >
                検索する
              </BaseButton>
            </StyledButtonContainer>
          </StyledConditionsContainer>
        )}
      </StyledSearchMenuDrawer>
    </>
  );
};

export { Presenter };
