import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';

type OwnProps = {
  handleClose: () => void;
};

const Container: React.VFC<OwnProps> = ({ handleClose }) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter handleClose={handleClose} />
  ) : (
    <Presenter handleClose={handleClose} />
  );
};
export { Container as BenefitOfPointChargeModal };
