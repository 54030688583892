import React from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/system';
import { neoTheme } from '/@/utils/theme';

const StyledCard = styled(Container)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: neoTheme.palette.background.contrast,
  padding: theme.spacing(3, 2) + ' !important',
}));

type Props = {
  children: React.ReactNode;
};

const Presenter: React.VFC<Props> = ({ children }) => (
  <StyledCard>
    {children}
  </StyledCard>
);

export { Presenter };
