import * as React from 'react';
import { styled } from '@mui/material/styles';
import { fontSize, fontType, FONT_SIZE } from './Index';
import { Typography, TypographyProps } from '@mui/material';

type StyledTypographyProps = TypographyProps & {
  fontType: fontType;
  fontSize: fontSize;
  isBold: boolean;
};
const StyledTypography = styled(Typography)<StyledTypographyProps>(({
  fontType,
  fontSize,
  isBold,
}) => ({
  fontSize:
    fontType === 'headline' ?
      fontSize === FONT_SIZE.SM ?
        '0.875rem' : fontSize === FONT_SIZE.MD ?
          '1rem' : '1.3125rem'
      :
      fontSize === FONT_SIZE.SM ?
        '0.75rem' : fontSize === FONT_SIZE.MD ?
          '0.875rem' : '1rem',
  lineHeight: '1.75',
  fontWeight: isBold ? 'bold' : 'normal',
}));

type Props = {
  fontType: fontType;
  fontSize: fontSize;
  isBold: boolean;
  children: React.ReactNode;
};
const Presenter: React.VFC<Props> = ({ fontType, fontSize, isBold, children }) => (
  <StyledTypography fontType={fontType} fontSize={fontSize} isBold={isBold}>{children}</StyledTypography>
)

export { Presenter };
