import React from 'react';
import {
  Card,
  Typography,
  Grid2 as Grid,
  IconButton,
  Button,
  ButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { formatToDateJa } from '/@/utils/formatDate';
import type { PointWalletState } from '/@/store/app/pointWallet';
import { POINT_FONT_SIZE, Points } from '/@/components/shared/points/Index';

export type PointWallet = NonNullable<PointWalletState>;

const StyledMainCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
}));
const StyledPointDisplayArea = styled(Grid)({
  height: '2rem',
});
// 表示調整用
const StyledLoader = styled('div')(({ theme }) => ({
  marginTop: `-${theme.spacing(2)}`,
  transform: 'scale(0.5)',
}));
const StyledPointAvailablePeriodContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  marginTop: theme.spacing(2),
  fontSize: '0.75rem',
  fontWeight: 'bold',
}));
const StyledPointAvailablePeriod = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontSize: '0.875rem',
}));
type StyledButtonProps = ButtonProps & { hasPoint: boolean };
const StyledPointBrandsPageButton = styled(Button)<StyledButtonProps>(
  ({ hasPoint, theme }) => ({
    width: '100%',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1, 4),
    borderRadius: theme.spacing(4),
    ...(!hasPoint && {
      color: theme.palette.common.white + ' !important', // MEMO: MUIのデフォルトの色を上書きするため
    }),
  }),
);
const StyledPointLogsButtonWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: theme.spacing(4),
  width: theme.spacing(4),
}));
const StyledPointPointLogsPageButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  height: '70%',
  width: '70%',
}));
const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
  height: theme.spacing(1.5),
  width: theme.spacing(1.5),
}));

type Props = {
  pointWallet: PointWallet | null;
  getPointWalletStatus: Status;
  handleNavigatePointBrands: () => void;
  handleNavigatePointLogs: () => void;
};

const Presenter: React.VFC<Props> = ({
  pointWallet,
  getPointWalletStatus,
  handleNavigatePointBrands,
  handleNavigatePointLogs,
}) => (
  <StyledMainCard>
    <Grid container justifyContent="space-between">
      <StyledPointDisplayArea>
        {getPointWalletStatus === STATUS.LOADING ? (
          <StyledLoader>
            <PartialLoader />
          </StyledLoader>
        ) : (
          <Grid container direction="row" alignItems="baseline">
            <Points points={pointWallet?.point} fontSize={POINT_FONT_SIZE.XL} />
          </Grid>
        )}
      </StyledPointDisplayArea>
      <StyledPointLogsButtonWrapper>
        <StyledPointPointLogsPageButton
          color="secondary"
          onClick={handleNavigatePointLogs}
          data-cy="handleNavigatePointLogsButton"
          data-gtm-click="homePage-pointSection-pointCard-handleNavigatePointLogsButton"
        >
          <StyledArrowForwardIosIcon />
        </StyledPointPointLogsPageButton>
      </StyledPointLogsButtonWrapper>
    </Grid>
    {pointWallet?.point !== undefined && pointWallet.point > 0 && (
      <StyledPointAvailablePeriodContent variant="body2">
        直近のポイント有効期限
        <StyledPointAvailablePeriod>
          {pointWallet?.nearestAvailableWithdrawableUntilAt
            ? formatToDateJa(pointWallet.nearestAvailableWithdrawableUntilAt)
            : '-'}
        </StyledPointAvailablePeriod>
      </StyledPointAvailablePeriodContent>
    )}
    <StyledPointBrandsPageButton
      variant="contained"
      disableElevation
      color="primary"
      onClick={handleNavigatePointBrands}
      hasPoint={!!pointWallet?.point}
      disabled={!pointWallet?.point}
      data-cy="handleNavigatePointBrandsButton"
      data-gtm-click="homePage-pointSection-pointCard-handleNavigatePointBrandsButton"
    >
      ポイントを使う
    </StyledPointBrandsPageButton>
  </StyledMainCard>
);

export { Presenter };
