import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { pointUpdatedSubscriptionThunk } from '/@/store/api/subscriptions/pointUpdatedSubscription';
import { useAppDispatch, useAppSelector } from '/@/store';

const Container: React.VFC<{
  children: React.ReactNode;
  headerTitle?: string;
}> = ({ children, headerTitle }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(pointUpdatedSubscriptionThunk());
  }, [dispatch]);

  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter headerTitle={headerTitle}>{children}</NeoPresenter>
  ) : (
    <Presenter headerTitle={headerTitle}>{children}</Presenter>
  );
};

export { Container as MainLayout };
