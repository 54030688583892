import React from 'react';
import { Box, Button, ButtonProps, styled } from '@mui/material';
import { ButtonLoader } from '/@/components/shared/loader/buttonLoader/Index';
import { neoTheme } from '/@/utils/theme';

type StyledButtonProps = ButtonProps & { isTransparent: boolean };
const StyledButton = styled(Button)<StyledButtonProps>(({ theme, isTransparent }) => ({
  height: '48px',
  width: '100%',
  backgroundColor: isTransparent ? neoTheme.palette.common.white : neoTheme.palette.common.black,
  color: isTransparent ? neoTheme.palette.common.black : neoTheme.palette.common.white,
  boxSizing: 'border-box',
  border: isTransparent ? `2px solid ${neoTheme.palette.grey[100]}` : 'none',
  borderRadius: theme.spacing(4),
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: isTransparent ? neoTheme.palette.common.white : neoTheme.palette.common.black,
    color: isTransparent ? neoTheme.palette.common.black : neoTheme.palette.common.white,
    border: isTransparent ? 'none' : `2px solid ${neoTheme.palette.grey[100]}`,
  },
  '&.Mui-disabled': {
    backgroundColor: neoTheme.palette.grey[400],
    color: neoTheme.palette.grey[700],
  },
}));

type Props = {
  loading?: boolean;
  isTransparent?: boolean;
  to?: string; // MEMO: Linkコンポーネントとして使う際に使用
} & ButtonProps;

const Presenter: React.VFC<Props> = ({
  children,
  disabled,
  loading,
  isTransparent = false,
  ...buttonProps
}) => {
  return (
    <>
      <StyledButton
        data-cy="loadingButton"
        {...buttonProps}
        disabled={loading || disabled}
        isTransparent={isTransparent}
      >
        {loading && <ButtonLoader />}
        <Box ml={1}>{children}</Box>
      </StyledButton>
    </>
  );
};

export type { Props as PresenterProps };

export { Presenter };
