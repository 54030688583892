import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';
import { MyGiftBase } from '/@/store/app/shared/types';

export const MYGIFT_DISPLAY_SIZE = {
  SM: 'sm',
  MD: 'md',
} as const;
export type MyGiftDisplaySize = typeof MYGIFT_DISPLAY_SIZE[keyof typeof MYGIFT_DISPLAY_SIZE];
type Props = {
  myGift: MyGiftBase;
  myGiftDisplaySize?: MyGiftDisplaySize;
  isHiddenMergeableText?: boolean;
};

const Container: React.VFC<Props> = ({
  myGift,
  myGiftDisplaySize,
  isHiddenMergeableText,
}) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter
      myGift={myGift}
      myGiftDisplaySize={myGiftDisplaySize}
      isHiddenMergeableText={isHiddenMergeableText}
    />
  ) : (
    <Presenter myGift={myGift} />
  );
};
export { Container as MyGift };
