import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { readAnnouncementGql } from './index.gql';
import {
  ReadAnnouncementMutation,
  ReadUserAnnouncementInput,
} from '/@/api/graphql/internalApi/types';

type ReadUserAnnouncementResponse = FetchResult<ReadAnnouncementMutation>;

const readAnnouncement = (
  input: ReadUserAnnouncementInput,
): Promise<ReadUserAnnouncementResponse> => {
  return internalApiClient.mutate({
    mutation: readAnnouncementGql,
    variables: input,
  });
};

export { readAnnouncement };

export type { ReadUserAnnouncementResponse };
