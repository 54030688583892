export const publicAssetsListForLpConvertToPointPage = {
  HIGH_RETURN_BRANDS_IMAGE:
    'lpConvertToPointPage/campaignSection/highReturnBrandsImage',
  EXCHANGEABLE_BRANDS_IMAGE:
    'lpConvertToPointPage/campaignSection/exchangeableBrandsImage',
  BENEFITS_OF_CONVERT_SECTION_SUBSECTION1:
    'lpConvertToPointPage/benefitsOfConvertSection/subsection1',
  BENEFITS_OF_CONVERT_SECTION_SUBSECTION3:
    'lpConvertToPointPage/benefitsOfConvertSection/subsection3',
};

export const publicAssetsListForLpChargeSerialCodePage = {
  HIGH_RETURN_BRANDS_IMAGE:
    'lpChargeSerialCodePage/campaignSection/highReturnBrandsImage',
  EXCHANGEABLE_BRANDS_IMAGE:
    'lpChargeSerialCodePage/campaignSection/exchangeableBrandsImage',
  BENEFITS_OF_CONVERT_SECTION_SUBSECTION1:
    'lpChargeSerialCodePage/benefitsOfConvertSection/subsection1',
  BENEFITS_OF_CONVERT_SECTION_SUBSECTION3:
    'lpChargeSerialCodePage/benefitsOfConvertSection/subsection3',
};

export const publicAssetsListForLpCampaignTiktok = {
  HIGH_RETURN_BRANDS_IMAGE:
    'lpCampaignTiktok/campaignSection/highReturnBrandsImage',
  EXCHANGEABLE_BRANDS_IMAGE:
    'lpCampaignTiktok/campaignSection/exchangeableBrandsImage',
  BENEFITS_OF_CONVERT_SECTION_SUBSECTION1:
    'lpCampaignTiktok/benefitsOfConvertSection/subsection1',
  BENEFITS_OF_CONVERT_SECTION_SUBSECTION3:
    'lpCampaignTiktok/benefitsOfConvertSection/subsection3',
};

export const publicAssetsListForLpMergeToPointPage = {
  HIGH_RETURN_BRANDS_IMAGE:
    'lpMergeToPointPage/campaignSection/highReturnBrandsImage',
  EXCHANGEABLE_BRANDS_IMAGE:
    'lpMergeToPointPage/campaignSection/exchangeableBrandsImage',
  BENEFITS_OF_CONVERT_SECTION_SUBSECTION1:
    'lpMergeToPointPage/benefitsOfConvertSection/subsection1',
  BENEFITS_OF_CONVERT_SECTION_SUBSECTION3:
    'lpMergeToPointPage/benefitsOfConvertSection/subsection3',
};
